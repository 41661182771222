import React, { useEffect } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { toast } from "react-toastify";
import { Delete, Edit } from "@mui/icons-material";
import { NavLink, useNavigate } from "react-router-dom";
import { Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CreateUserForm } from "./createUserForm.jsx";
import PersonIcon from "@mui/icons-material/Person";
import { DataContext } from "../../Context/dataContext.jsx";
import { deleteUser } from "../../API/users.api.js";
import { ColumnChooser, ColumnFixing } from "devextreme-react/tree-list";
import { ConfirmationDialog } from "../../General/confirmationDialog.jsx";
import { AuthContext } from "../../Context/authContext.jsx";
import { CustomModal } from "./modal.jsx";

const saleAmountFormat = {
  style: "currency",
  currency: "USD",
  useGrouping: true,
  significantDigits: 2,
};
let userId = -1;

function UsersList() {
  const { users, updateUsers, openModal } = React.useContext(DataContext);
  const [openDialog, setOpenDialog] = React.useState(false);
  const { getToken, logout } = React.useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    updateUsers();
  }, []);

  const handleModal = () => {
    openModal(-1);
  };

  const deleteUserLocal = () => {
    const id = userId;
    userId = -1;
    deleteUser(id, getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't delete user due to an internal server error. Please try again later."
        );
      toast.success("User deleted successfully.");
      updateUsers();      
      setOpenDialog(false);
    });
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Editar"
          style={{ marginRight: 10, cursor: "pointer", color: "#337ab7" }}
        >
          <NavLink
            onClick={(e) => {
              const user = users.find(
                (element) => element.id === rowInfo.data.id
              );              
              openModal(user);
              e.preventDefault();
            }}
            to={``}
          >
            <Edit />
          </NavLink>
        </Tooltip>

        <Tooltip
          title="Eliminar"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Delete
            style={{ cursor: "pointer", color: "#f01e0f" }}
            onClick={() => {
              userId = rowInfo.data.id;
              setOpenDialog(true);
            }}
          />
        </Tooltip>
      </>
    );
  };

  return (
    <div>
      <div
        style={{
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 10px",
          borderRadius: "15px",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PersonIcon fontSize="large" sx={{ margin: "0 2px" }} />
        <h1 style={{ margin: 10 }}>Users</h1>
      </div>
      <DataGrid
        dataSource={users}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="nextColumn"
        // defaultColumns={columns}
        // rowRender={plantillaFila}
      >
        <ColumnChooser enabled={true} />
        <ColumnFixing enabled={true} />
        <Column dataField="id" caption="id" dataType="number" visible={false} />
        <Column
          dataField="nombreCompleto"
          caption="Name"
          dataType="string"
          allowSorting={true}
        />
        <Column
          dataField="email"
          caption="Email"
          dataType="string"
          allowSorting={true}
        />
        <Column
          dataField="telefonos"
          caption="Phone number"
          dataType="string"
          allowSorting={true}
        />
        <Column
          dataField="noCuenta"
          caption="Account No."
          dataType="string"
          // width={50}
          allowSorting={true}
        />
        <Column
          dataField="banco"
          caption="Bank"
          dataType="string"
          // width={50}
          allowSorting={true}
        />
        <Column
          dataField="tipoCuenta"
          caption="Account Type"
          dataType="string"
          allowSorting={true}
        />
        <Column
          dataField="pagoMinuto"
          caption="Salary/Min"
          dataType="number"
          format={saleAmountFormat}
          width={110}
          allowSorting={true}
        />
        <Column
          dataField="rol"
          caption="Role"
          dataType="string"                    
          allowSorting={true}
        />
        <Column
          caption="Options"
          allowFiltering={false}
          allowSorting={false}
          cellRender={cellOpciones}
          width={120}
        />
        <FilterRow visible={true} />
        <FilterPanel visible={false} />
        {/* <FilterBuilderPopup position={filterBuilderPopupPosition} /> */}
        <HeaderFilter visible={true} />
        {/* <Scrolling mode="infinite" /> */}

        {/* <GroupPanel visible={true} /> */}
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
        {/* <Paging defaultPageSize={10} /> */}
        <Paging defaultPageSize={50} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[50, 100, 150, 200]}
          showInfo={true}
        />
      </DataGrid>
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          right: 10,
          bottom: 10,
          backgroundColor: "#aebd36",
        }}
        onClick={handleModal}
      >
        <AddIcon />
      </Fab>
      <CustomModal>
        <CreateUserForm />
      </CustomModal>
      <ConfirmationDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onCancel={() => setOpenDialog(false)}
        onSuccess={deleteUserLocal}
      />
    </div>
  );
}

export { UsersList };
