import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Main } from "../containers/drawerMain";
import { DrawerHeader } from "../containers/drawerHeader";
import { AppBar } from "../containers/drawerAppBar";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import PersonIcon from "@mui/icons-material/Person";
import MicIcon from "@mui/icons-material/Mic";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AssignmentIcon from "@mui/icons-material/Assignment";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import { Outlet } from "react-router-dom";
import { ADMIN, DRAWER_COLOR } from "../../Utils/config";
import MySettings from "../../General/mySettingsComponent";
import { NonDecoratedLink } from "../../General/link";
import { AuthContext } from "../../Context/authContext";
import { ChangePasswordModal } from "../../Login/components/changePasswordModal";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { LogoComponent } from "../containers/logoComponent";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import styled from "@emotion/styled";
import { MoneyResume } from "./moneyResume";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PlagiarismIcon from '@mui/icons-material/Plagiarism';

const drawerWidth = 280;

const CustomizedListItemButton = styled(ListItemButton)`
  &.Mui-selected {
    background-color: #acb236;
  }
  &.Mui-selected:hover {
    background-color: #acb236;
  }
  :hover {
    background-color: rgba(172, 178, 54, 0.2);
  }
`;

function MainDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [reportesExpanded, setReportsExpanded] = React.useState(true);
  const { getRol, cambioClave, getSupervisor } = React.useContext(AuthContext);
  const [changePassword, setChangePassword] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  React.useEffect(() => {
    setChangePassword(cambioClave());
  }, []);

  const handleClick = () => {
    setReportsExpanded(!reportesExpanded);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isVisible = (menu) => {
    const rol = getRol();
    const supervisor = getSupervisor();    
    switch (menu) {
      case "users":
        return rol === ADMIN ? 1 : 0;
      case "clients":
        return rol === ADMIN ? 1 : 0;
      case "periods":
        return rol === ADMIN ? 1 : 0;
      case "recordings":
        return 1;
      case "minutes":
        return 1;
      case "stats":
        return rol === ADMIN ? 1 : 0;
      case "clientsminutes":
        return rol === ADMIN ? 1 : 0;
      case "availability":
        return rol === ADMIN ? 0 : 1;
      case "availabilitytable":
        return rol === ADMIN ? 1 : 0;
        case "supervisorview":
          return supervisor;
      default:
        return 0;
    }
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const menuItems = {
    main: [
      {
        id: 1,
        name: "users",
        label: "Users",
        Icon: PersonIcon,
      },
      {
        id: 2,
        name: "recordings",
        label: "Recordings",
        Icon: MicIcon,
      },
      {
        id: 3,
        name: "clients",
        label: "Clients",
        Icon: AssignmentIndIcon,
      },
      {
        id: 4,
        name: "periods",
        label: "Payment Periods",
        Icon: InsertInvitationIcon,
      },
      {
        id: 5,
        name: "availabilitytable",
        label: "Availability table",
        Icon: CalendarMonthOutlinedIcon,
      },
      {
        id: 6,
        name: "supervisorview",
        label: "Supervisor View",
        Icon: PlagiarismIcon,
      }
    ],
    reports: [
      {
        id: 7,
        name: "stats",
        label: "Recording Stats",
        Icon: AssignmentIcon,
      },
      {
        id: 8,
        name: "minutes",
        label: "Payments",
        Icon: LocalAtmIcon,
      },
      {
        id: 9,
        name: "clientsminutes",
        label: "Income Balance",
        Icon: CurrencyExchangeIcon,
      }
    ],
  };

  return (
    <Box sx={{ display: "flex" }}>
      <ChangePasswordModal
        restartPassword={changePassword}
        message="You need to change your password after first login"
      />
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        drawerwidth={drawerWidth}
        sx={{
          backgroundColor: DRAWER_COLOR,
          flex: 1,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }), color: "white" }}
            >
              <MenuIcon />
            </IconButton>
            <div style={{ display: "flex", alignItems: "center" }}>
              <LogoComponent />
              {getRol() === ADMIN ? <MoneyResume /> : <></>}
            </div>
          </div>
          <MySettings setChangePassword={setChangePassword} />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: DRAWER_COLOR,
            color: "white",
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} sx={{ color: "white" }}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.main.map((item) => (
            <NonDecoratedLink to={item.name} visible={isVisible(item.name)}>
              <CustomizedListItemButton
                key={item.name}
                onClick={(event) => handleListItemClick(event, item.id)}
                selected={selectedIndex === item.id}
              >
                <ListItemIcon sx={{ color: "white" }}>
                  <item.Icon />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </CustomizedListItemButton>
            </NonDecoratedLink>
          ))}
          <CustomizedListItemButton onClick={handleClick}>
            <ListItemIcon sx={{ color: "white" }}>
              <StackedBarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
            {reportesExpanded ? <ExpandLess /> : <ExpandMore />}
          </CustomizedListItemButton>
          <Collapse in={reportesExpanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
            {menuItems.reports.map((item) => (
            <NonDecoratedLink to={item.name} visible={isVisible(item.name)}>
              <CustomizedListItemButton
                key={item.name}
                onClick={(event) => handleListItemClick(event, item.id)}
                selected={selectedIndex === item.id}
              >
                <ListItemIcon sx={{ color: "white" }}>
                  <item.Icon />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </CustomizedListItemButton>
            </NonDecoratedLink>
          ))}
            </List>
          </Collapse>
        </List>
      </Drawer>
      <Main open={open} drawerwidth={drawerWidth}>
        <DrawerHeader />
        <div style={{ maxHeight: "calc(100vh - 80px)", overflowY: "auto" }}>
          <Outlet />
        </div>
      </Main>
    </Box>
  );
}

export { MainDrawer };
