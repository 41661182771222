import { Tooltip } from "@mui/material";
import React from "react";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { ColumnChooser, ColumnFixing } from "devextreme-react/tree-list";
import { Delete, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import Confirm from "../../General/confirm";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/authContext";
import { deleteCliente } from "../../API/clients.api";

const saleAmountFormat = {
  style: "currency",
  currency: "USD",
  useGrouping: true,
  significantDigits: 2,
};

let clientId = -1; 

function ClienteList({
  clientes,
  setModalOpen,
  setClienteActivo,
  updateClientes,
}) {
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const { logout } = React.useContext(AuthContext);
  const navigate = useNavigate();

  function removeCliente() {
    deleteCliente(clientId).then((response) => {
      if (response.status === 200) {
        toast.success("client deleted successfully");
        setClienteActivo({});
        setOpenConfirm(false);
        updateClientes();
      } else if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      } else {
        return toast.error(
          "Couldn't retrieve periods data. Please try again later."
        );
      }
    });
  }

  const cellOpciones = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Editar"
          style={{ marginRight: 10, cursor: "pointer", color: "#337ab7" }}
        >
          <Edit
            onClick={(e) => {
              setModalOpen(true);
              const clienteActivo = clientes.find(
                (cliente) => cliente.id === rowInfo.data.id
              );
              setClienteActivo(clienteActivo);
            }}
          />
        </Tooltip>

        <Tooltip
          title="Eliminar"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Delete
            style={{ cursor: "pointer", color: "#f01e0f" }}
            onClick={() => {
                setOpenConfirm(true)
                clientId = rowInfo.data.id;

            }}
          />
        </Tooltip>
      </>
    );
  };
  return (
    <div>
      <div
        style={{
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 10px",
          borderRadius: "15px",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AssignmentIndIcon fontSize="large" sx={{ margin: "0 2px" }} />
        <h1 style={{ margin: 10 }}>Clients</h1>
      </div>
      <DataGrid
        dataSource={clientes}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="nextColumn"
        // defaultColumns={columns}
        // rowRender={plantillaFila}
      >
        <ColumnChooser enabled={true} />
        <ColumnFixing enabled={true} />
        <Column dataField="id" caption="id" dataType="number" visible={false} />
        <Column
          dataField="nombre"
          caption="Name"
          dataType="string"
          allowSorting={true}
        />
        <Column
          dataField="correo"
          caption="Email"
          dataType="string"
          allowSorting={true}
        />
        <Column
          dataField="pagoMinuto"
          caption="Payment/Min"
          dataType="number"
          format={saleAmountFormat}
          allowSorting={true}
        />
        <Column
          caption="Options"
          allowFiltering={false}
          allowSorting={false}
          cellRender={cellOpciones}
        />
        <FilterRow visible={true} />
        <FilterPanel visible={false} />
        {/* <FilterBuilderPopup position={filterBuilderPopupPosition} /> */}
        <HeaderFilter visible={true} />
        {/* <Scrolling mode="infinite" /> */}

        {/* <GroupPanel visible={true} /> */}
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
        {/* <Paging defaultPageSize={10} /> */}
        <Paging defaultPageSize={50} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[50, 100, 150, 200]}
          showInfo={true}
        />
      </DataGrid>
      <Confirm
        open={openConfirm}
        title={"Delete client"}
        text={"¿Are you sure you want to delete this item?"}
        onConfirm={removeCliente}
        handleCloseProp={() => setOpenConfirm(false)}
      />
    </div>
  );
}

export { ClienteList };
