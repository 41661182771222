import React, { useEffect } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { Button, InputAdornment, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getClients } from "../../API/clients.api";
import { getReviewers, getTranslators } from "../../API/users.api";
import { toast } from "react-toastify";
import { createRecording, updateRecording } from "../../API/recordings.api";
import { AuthContext } from "../../Context/authContext";
import { DateBox } from "devextreme-react";

function RecordingConfigurations({ files, setFiles }) {
  const [filesToPost, setFilesToPost] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [translators, setTranslators] = React.useState([]);
  const [reviewers, setReviewers] = React.useState([]);
  const { getToken, logout } = React.useContext(AuthContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    updateClientes();
    updateReviewers();
    updateTranslators();
  }, []);

  React.useEffect(() => {
    setFilesToPost(files.map((file) => file));
  }, [files]);

  async function updateClientes() {
    getClients(getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve client's information due to an internal server error."
        );
      setClients(response.body);
    });
  }

  async function updateTranslators() {
    getTranslators(getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve translator's information due to an internal server error."
        );
      setTranslators(response.body);
    });
  }

  async function updateReviewers() {
    getReviewers(getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve reviewer's information due to an internal server error."
        );
      setReviewers(response.body);
    });
  }

  function modifyValue(id, property, newValue) {
    const file = filesToPost.find((element) => element.id === id);
    file[property] = newValue;    
  }

  function findValue(id, property) {
    const file = filesToPost.find((element) => element.id === id);
    return file[property];
  }

  function navigateToRecordings() {
    navigate("/home/recordings");
  }

  const translatorMultiplierOptions = (rowInfo) => {
    return (
      <>
        <TextField
          id="translatorMultiplier"
          variant="outlined"
          type="number"
          fullWidth
          size="small"
          defaultValue={findValue(rowInfo.data.id, "translatorMultiplier")}
          onChange={(e) =>
            modifyValue(rowInfo.data.id, "translatorMultiplier", e.target.value)
          }
        />
      </>
    );
  };

  const reviewerMultiplierOptions = (rowInfo) => {
    return (
      <>
        <TextField
          id="reviewerMultiplier"
          variant="outlined"
          type="number"
          fullWidth
          size="small"
          defaultValue={findValue(rowInfo.data.id, "reviewerMultiplier")}
          onChange={(e) =>
            modifyValue(rowInfo.data.id, "reviewerMultiplier", e.target.value)
          }
        />
      </>
    );
  };

  const durationRow = (rowInfo) => {
    return (
      <>
        <TextField
          id="outlinedMinutes"
          type="number"
          size="small"
          sx={{ width: "85px" }}
          defaultValue={findValue(rowInfo.data.id, "minutos")}
          InputProps={{
            endAdornment: <InputAdornment position="end">m</InputAdornment>,
          }}
          onChange={(e) =>
            modifyValue(rowInfo.data.id, "minutos", e.target.value)
          }
        />
        <TextField
          id="outlinedSeconds"
          type="number"
          size="small"
          sx={{ width: "85px" }}
          defaultValue={findValue(rowInfo.data.id, "segundos")}
          InputProps={{
            endAdornment: <InputAdornment position="end">s</InputAdornment>,
          }}
          onChange={(e) =>
            modifyValue(rowInfo.data.id, "segundos", e.target.value)
          }
        />
      </>
    );
  };

  const dueDate = (rowInfo) => {
    return (
      <>
        <DateBox
          height="45px"
          defaultValue={findValue(rowInfo.data.id, "dueDate")}
          onValueChanged={(e) =>
            modifyValue(rowInfo.data.id, "dueDate", e.value)
          }
          type="datetime"
        />
      </>
    );
  };

  const maxDueDate = (rowInfo) => {
    return (
      <>
        <DateBox
          height="45px"
          defaultValue={findValue(rowInfo.data.id, "maxDueDate")}
          onValueChanged={(e) =>
            modifyValue(rowInfo.data.id, "maxDueDate", e.value)
          }
          type="datetime"
        />
      </>
    );
  };

  const publicationDate = (rowInfo) => {
    return (
      <>
        <DateBox
          height="45px"
          defaultValue={findValue(rowInfo.data.id, "publicationDate")}
          onValueChanged={(e) =>
            modifyValue(rowInfo.data.id, "publicationDate", e.value)
          }
          type="date"
        />
      </>
    );
  };

  const clientRow = (rowInfo) => {
    return (
      <>
        <TextField
          id="client"
          select
          label="Client"
          defaultValue={findValue(rowInfo.data.id, "client")}
          onChange={(e) =>
            modifyValue(rowInfo.data.id, "client", e.target.value)
          }
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        >
          {clients.map((client) => (
            <MenuItem key={client.id} value={client.id}>
              {client.nombre}
            </MenuItem>
          ))}
        </TextField>
      </>
    );
  };

  const translatorRow = (rowInfo) => {
    return (
      <>
        <TextField
          id="translator"
          select
          label="Translator"
          defaultValue={findValue(rowInfo.data.id, "translator")}
          onChange={(e) =>
            modifyValue(rowInfo.data.id, "translator", e.target.value)
          }
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        >
          {translators.map((translator) => (
            <MenuItem key={translator.id} value={translator.id}>
              {translator.nombreCompleto}
            </MenuItem>
          ))}
        </TextField>
      </>
    );
  };

  const reviewerRow = (rowInfo) => {
    return (
      <>
        <TextField
          id="reviewer"
          select
          label="Reviewer"
          defaultValue={findValue(rowInfo.data.id, "reviewer")}
          onChange={(e) =>
            modifyValue(rowInfo.data.id, "reviewer", e.target.value)
          }
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        >
          {reviewers.map((reviewer) => (
            <MenuItem key={reviewer.id} value={reviewer.id}>
              {reviewer.nombreCompleto}
            </MenuItem>
          ))}
        </TextField>
      </>
    );
  };

  function modifyFilesToPost() {
    filesToPost.forEach((file) => {
      file.dueDate = new Date(file.dueDate).toISOString();
      file.minutos = file.minutos ? parseInt(file.minutos) : 0;
      file.segundos = file.segundos ? parseInt(file.segundos) : 0;
      file.duration = file.minutos + file.segundos / 60;
      file.publicationDate = new Date(
        file.publicationDate + "T06:00:00.000Z"
      ).toISOString();
      file.maxDueDate = new Date(file.maxDueDate).toISOString();
    });
  }

  function saveChanges() {
    modifyFilesToPost();
    createRecording(filesToPost, getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "There was an error creating the recording. Please try again later."
        );
      toast.success("Recording successfully saved");
      navigateToRecordings();
    });
  }

  return (
    <>
      <DataGrid
        dataSource={filesToPost}
        allowColumnReordering={true}
        allowColumnResizing={true}
        // defaultColumns={columns}
        // rowRender={plantillaFila}
      >
        <Column dataField="id" caption="id" dataType="number" visible={false} />
        <Column
          dataField="name"
          caption="Name"
          dataType="string"
          allowSorting={true}
          allowResizing
        />
        <Column
          dataField="link"
          caption="Link"
          dataType="string"
          allowSorting={true}
          visible={false}
        />
        <Column
          dataField="convict"
          caption="Convict No."
          dataType="string"
          allowSorting={true}
          visible={false}
        />
        <Column
          dataField="day"
          caption="Day"
          dataType="string"
          width={100}
          allowSorting={true}
          visible={false}
        />
        <Column
          dataField="month"
          caption="Month"
          dataType="string"
          width={100}
          allowSorting={true}
          visible={false}
        />
        <Column
          dataField="year"
          caption="Year"
          dataType="string"
          width={100}
          allowSorting={true}
          visible={false}
        />
        <Column
          dataField="translatorMultiplier"
          caption="Trans. Mult."
          dataType="number"
          width={100}
          cellRender={translatorMultiplierOptions}
          allowFiltering={false}
          allowSorting={false}
        />
        <Column
          dataField="reviewerMultiplier"
          caption="Rev. Mult."
          width={100}
          dataType="number"
          cellRender={reviewerMultiplierOptions}
          allowFiltering={false}
          allowSorting={false}
        />
        <Column
          dataField="duration"
          caption="Duration"
          width={190}
          dataType="number"
          cellRender={durationRow}
          allowFiltering={false}
          allowSorting={false}
          allowEditing={true}
        />
        <Column
          dataField="publicationDate"
          caption="Publication date"
          dataType="date"
          cellRender={publicationDate}
          allowFiltering={false}
          allowSorting={false}
        />
        <Column
          dataField="dueDate"
          caption="Due Date"
          dataType="date"
          cellRender={dueDate}
          allowFiltering={false}
          allowSorting={false}
        />
        <Column
          dataField="maxDueDate"
          caption="Max Due Date"
          dataType="date"
          cellRender={maxDueDate}
          allowFiltering={false}
          allowSorting={false}
        />
        <Column
          dataField="client"
          caption="Client"
          cellRender={clientRow}
          allowFiltering={false}
          allowSorting={false}
        />
        <Column
          dataField="translator"
          caption="Translator"
          cellRender={translatorRow}
          allowFiltering={false}
          allowSorting={false}
        />
        <Column
          dataField="reviewer"
          caption="Reviewer"
          cellRender={reviewerRow}
          allowFiltering={false}
          allowSorting={false}
        />
        {/* <Column
          caption="Opciones"
          allowFiltering={false}
          allowSorting={false}
          cellRender={cellOpciones}
          width={120}
        /> */}
        <FilterRow visible={true} />
        <FilterPanel visible={false} />
        {/* <FilterBuilderPopup position={filterBuilderPopupPosition} /> */}
        <HeaderFilter visible={true} />
        {/* <Scrolling mode="infinite" /> */}

        {/* <GroupPanel visible={true} /> */}
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
        {/* <Paging defaultPageSize={10} /> */}
        <Paging defaultPageSize={20} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[50, 100, 150, 200]}
          showInfo={true}
        />
      </DataGrid>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <Button
          variant="contained"
          color="error"
          style={{ width: 150, margin: "0 10px" }}
          onClick={navigateToRecordings}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#aebd36",
            width: 150,
            margin: "0 10px",
          }}
          onClick={saveChanges}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export { RecordingConfigurations };
