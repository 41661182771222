import { Divider } from "@mui/material";
import React from "react";
import { Periodo } from "./periodo";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

function PeriodoList({
  periodos,
  setModalOpen,
  setPeriodoActivo,
  updatePeriodos,
}) {
  return (
    <div>
      <div
        style={{
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 10px",
          borderRadius: "15px",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LocalAtmIcon fontSize="large" sx={{ margin: "0 2px" }} />
        <h1 style={{ margin: 10 }}>Payment periods</h1>
      </div>
      <Divider />
      {periodos.map((periodo) => {
        return (
          <Periodo
            key={periodo.id}
            periodo={periodo}
            setModalOpen={setModalOpen}
            setPeriodoActivo={setPeriodoActivo}
            updatePeriodos={updatePeriodos}
          />
        );
      })}
    </div>
  );
}

export { PeriodoList };
