import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { POSTCliente, PUTCliente } from "../../API/clients.api";

function ClientesForm({ setOpen, updateClientes, clienteActivo }) {
  const [id, setId] = useState(clienteActivo.id || -1);
  const [nombre, setNombre] = useState(clienteActivo.nombre || "");
  const [correo, setCorreo] = useState(clienteActivo.correo || "");
  const [pagoMinuto, setPagoMinuto] = useState(clienteActivo.pagoMinuto || 0.0);

  function save() {
    const cliente = {
      nombre,
      correo,
      pagoMinuto,
    };    
    if (clienteActivo.id) {      
      cliente.id = clienteActivo.id;
      updateCliente(cliente);
    } else {
      createCliente(cliente);
    }
  }

  const createCliente = (cliente) => {
    POSTCliente(cliente).then((response) => {      
      if (response.status === 201) {          
        toast.success("Client created successfully");
        setOpen(false);
        updateClientes();
      } else {
        toast.error("There was an error creating the client. Please try again later");
      }
    });
  };

  const updateCliente = (cliente) => {
    PUTCliente(cliente).then((response) => {
        if (response.status === 200) {
          toast.success("Client updated successfully");
          setOpen(false);
          updateClientes();
        } else {
          toast.error("There was an error updating the client. Please try again later");
        }
      });
  };

  return (
    <div style={{ maxHeight: "550px", overflow: "auto" }}>
      <Grid container spacing={3} sx={{ marginTop: "0px" }}>
        <Grid item xs={6}>
          <TextField
            id="nombre"
            label="Name"
            variant="outlined"
            value={nombre}
            style={{ width: "100%" }}
            onChange={(e) => setNombre(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="correo"
            label="Email"
            variant="outlined"
            value={correo}
            multiline
            style={{ width: "100%" }}
            onChange={(e) => setCorreo(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel htmlFor="standard-adornment-amount">
              Payment/min
            </InputLabel>
            <OutlinedInput
              type="number"
              id="filled-adornment-amount"
              fullWidth
              placeholder="Salary/minute"
              value={pagoMinuto}
              onChange={(e) => setPagoMinuto(e.target.value)}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              label="Payment/min"
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          lg={12}
          sm={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "red",
              color: "white",
              marginRight: "20px",
            }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#69bd4b",
              color: "white",
              marginRight: "20px",
            }}
            onClick={() => save()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export { ClientesForm };
