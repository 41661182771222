import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./Login/containers/login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MainDrawer } from "./Layout/components/drawer";
import { UserOperationSelection } from "./Admin/Users/userOperationSelection";
import { ProtectedRoute } from "./Security/protectedRoute";
import { RecordingsOperationSelection } from "./Admin/Recordings/recordingsOperationSelection";
import { TranslatorRecordings } from "./Translator/translatorRecordingsList";
import { PeriodosMain } from "./Admin/Reports/periodosMain";
import { ReacordingEdition } from "./Admin/Recordings/recordingEdition";
import { MinutesWorkedSelection } from "./Reports/minutesWorkedSelection";
import { ClientesMain } from "./Admin/Clients/clientsMain";
import { ClientsMinutesWorked } from "./ClientsReport/clientsMinutesWorked";
import { AvailabilityUsers } from "./Availability/users/availabilityUsers";
import { AvailabilityAdmin } from "./Availability/admin/availabilityAdmin";
import { SupervisorView } from "./Supervisor/supervisorView";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <MainDrawer />
            </ProtectedRoute>
          }
        >
          <Route index element={<RecordingsOperationSelection />} />
          <Route path="users" element={<UserOperationSelection />} />
          <Route path="recordings" element={<RecordingsOperationSelection />}>
            <Route path="upload" element={<RecordingsOperationSelection />} />
            <Route path=":recordingId" element={<ReacordingEdition />} />
          </Route>
          <Route path="stats" element={<TranslatorRecordings />} />
          <Route path="supervisorview" element={<SupervisorView />} />
          <Route path="periods" element={<PeriodosMain />} />
          <Route path="minutes" element={<MinutesWorkedSelection />} />
          <Route path="clients" element={<ClientesMain />} />
          <Route path="clientsminutes" element={<ClientsMinutesWorked />} />
          <Route path="availability" element={<AvailabilityUsers />} />
          <Route path="availabilitytable" element={<AvailabilityAdmin />} />
        </Route>

        {/* <Route path="/home" element={<ProtectedRoute><Drawer /></ProtectedRoute>} /> */}
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
