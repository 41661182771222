import React, { useEffect } from "react";
import { AuthContext } from "../Context/authContext";
import { DashboardDataDisplay } from "../General/dashboardDataDisplay";
import { ADMIN, TRANSLATOR } from "../Utils/config";

function TranslatorRecordingStats({ recordings }) {
  const [asignados, setAsignados] = React.useState([]);
  const [pendientes, setPendientes] = React.useState([]);
  const [pendienteTraductor, setPendienteTraductor] = React.useState([]);
  const [pendienteRevisor, setPendienteRevisor] = React.useState([]);
  const [entregados, setEntregados] = React.useState([]);
  const { getRol } = React.useContext(AuthContext);

  useEffect(() => {
    updateRecordingsData();
  }, [recordings]);

  const getState = (recording) => {
    if (!recording.translatorFile) {
      return "Pending - Translator";
    }
    if (!recording.reviewerFile) {
      return "Pending - Reviewer";
    }
    return "Reviewed";
  };

  const updateRecordingsData = () => {
    const data = recordings;
    setAsignados(data);
    if (getRol() === TRANSLATOR) {
      setPendientes(data.filter((element) => !element.translatorFile));
      setEntregados(data.filter((element) => element.translatorFile));
    } else {
      setEntregados(
        data.filter((element) => element.reviewerFile && element.translatorFile)
      );
      setPendientes(
        data
          .filter((element) => !element.reviewerFile || !element.translatorFile)
          .map((element) => {
            return {
              ...element,
              cliente: element.Cliente?.nombre,
              traductor: element.Translator?.nombreCompleto,
              revisor: element.Reviewer?.nombreCompleto,
              estado: getState(element),
            };
          })
      );

      setPendienteTraductor(
        data
          .filter((element) => !element.translatorFile)
          .map((element) => {
            return {
              ...element,
              cliente: element.Cliente?.nombre,
              traductor: element.Translator?.nombreCompleto,
              revisor: element.Reviewer?.nombreCompleto,
              estado: getState(element),
            };
          })
      );

      setPendienteRevisor(
        data
          .filter((element) => !element.reviewerFile)
          .map((element) => {
            return {
              ...element,
              cliente: element.Cliente?.nombre,
              traductor: element.Translator?.nombreCompleto,
              revisor: element.Reviewer?.nombreCompleto,
              estado: getState(element),
            };
          })
      );
    }
  };

  return (
    <section
      style={{
        display: "flex",
        justifyContent: "space-around",
        margin: "20px 0px",
      }}
    >
      <DashboardDataDisplay
        value={asignados.length}
        description={"Assigned"}
        fontColor="white"
        backgroundColor="#202E3C"
      />
      <DashboardDataDisplay
        value={entregados.length}
        description={"Done"}
        fontColor="white"
        backgroundColor="#ACBB36"
      />
      <DashboardDataDisplay
        value={pendientes.length}
        description={"Pending"}
        fontColor="white"
        backgroundColor="red"
        visible={getRol() !== ADMIN}
      />
      <DashboardDataDisplay
        value={pendienteTraductor.length}
        description={"Pending - Translator"}
        fontColor="white"
        backgroundColor="red"
        visible={getRol() === ADMIN}
      />
      <DashboardDataDisplay
        value={pendienteRevisor.length}
        description={"Pending - Reviewer"}
        fontColor="white"
        backgroundColor="orange"
        visible={getRol() === ADMIN}
      />
    </section>
  );
}

export { TranslatorRecordingStats };
