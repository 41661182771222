import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CreateUserForm } from "./createUserForm";
import { UsersList } from "./usersList";

function UserOperationSelection() {
  const location = useLocation().pathname;

  if(location === "/home/users/create"){
      return <CreateUserForm />
  }
  return <UsersList />
}

export { UserOperationSelection };
