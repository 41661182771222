import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Backdrop, Fade } from "@mui/material";
import { DataContext } from "../../Context/dataContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "15px",
  boxShadow: 24,
};

function CustomModal({ children }) {
  const { isModalShown, closeModal } = React.useContext(DataContext);
  const handleClose = () => closeModal();  

  return (
    <div>
      <Modal
        open={isModalShown}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalShown}>
          <Box sx={style}>
            <div>{children}</div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export { CustomModal };
