import React, { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DateBox from "devextreme-react/date-box";
import NativeSelect from "@mui/material/NativeSelect";
import { Button } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { getConfig, putConfig } from "../../API/config.api";
import { toast } from "react-toastify";
const CONFIG_AVAILABILITY = 1;

const days = [  
  { id: 1, nombre: "Lunes" },
  { id: 2, nombre: "Martes" },
  { id: 3, nombre: "Miércoles" },
  { id: 4, nombre: "Jueves" },
  { id: 5, nombre: "Viernes" },
  { id: 6, nombre: "Sábado" },
  { id: 0, nombre: "Domingo" },
];

function AvailabilityConfigs() {
  const [initialDay, setInitialDay] = React.useState(4);
  const [initialHour, setInitialHour] = React.useState(
    new Date(1997, 11, 11, 12, 0)
  );
  const [lastDay, setlastDay] = React.useState(6);
  const [lastHour, setlastHour] = React.useState(new Date(1997, 11, 11, 13, 0));

  useEffect(() => {
    getConfig(CONFIG_AVAILABILITY).then(response => {
      if(response.status === 200){
        const availabilityConfig = JSON.parse(response.body.valor)        
        if(availabilityConfig) {
          setInitialDay(availabilityConfig.initialDay)
          setInitialHour(new Date(1997, 11, 11, availabilityConfig.initialHour, availabilityConfig.initialMinutes) )
          setlastDay(availabilityConfig.lastDay)
          setlastHour(new Date(1997, 11, 11, availabilityConfig.lastHour, availabilityConfig.lastMinutes) )
        }
      } else {
        toast.error("No se pudo recuperar la disponibilidad")
      }
    })
  }, [])

  function save() {
    const info = {
      initialDay,
      initialHour: initialHour.getHours(),
      initialMinutes: initialHour.getMinutes(),
      lastDay,
      lastHour: lastHour.getHours(),
      lastMinutes: lastHour.getMinutes(),
    };
    putConfig({valor: JSON.stringify(info)}, CONFIG_AVAILABILITY).then( response => {
      if(response.status === 200) {
        toast.success("Configuración del formulario modificada")
      } else {
        toast.error("No se pudo modificar la configuracion")
      }
    });    
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px",
      }}
    >
      <section style={{ display: "flex", alignItems: "center" }}>
        <b>Apertura de formulario: </b>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <Select
            labelId="initialDay"
            id="initialDay"
            value={initialDay}
            label="Dia apertura"
            onChange={(e) => setInitialDay(e.target.value)}
          >
            {days.map((day) => {
              return (
                <MenuItem key={`F${day.id}`} value={day.id}>
                  {day.nombre}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <DateBox
          value={initialHour}
          onValueChanged={(e) => setInitialHour(e.value)}
          type="time"
        />
      </section>
      <section
        style={{ display: "flex", alignItems: "center", margin: "0px 40px" }}
      >
        <b>Cierre de formulario: </b>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <Select
            labelId="lastday"
            id="lastday"
            value={lastDay}
            label="Dia cierre"
            onChange={(e) => setlastDay(e.target.value)}
          >
            {days.map((day) => {
              return (
                <MenuItem key={`L${day.id}`} value={day.id}>
                  {day.nombre}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <DateBox
          value={lastHour}
          onValueChanged={(e) => setlastHour(e.value)}
          type="time"
        />
      </section>
      <Button
        style={{
          backgroundColor: "#69bd4b",
          color: "white",
          marginRight: "20px",
          width: "100px",
        }}
        onClick={() => save()}
      >
        Guardar
      </Button>
    </div>
  );
}

export { AvailabilityConfigs };
