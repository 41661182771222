import React from "react";
import { toast } from "react-toastify";
import { getPresignedURL, uploadRecordings } from "../../API/recordings.api";
import Button from "@mui/material/Button";
import "./styles.css";
import { ASSIGNED, S3_BUCKET } from "../../Utils/config";
import { RecordingConfigurations } from "./recordingConfigurations";
import { RecordingsTitle } from "./recordingsTitle";
import { Grid, MenuItem, TextField } from "@mui/material";
import { getClients } from "../../API/clients.api";
import { getReviewers, getTranslators } from "../../API/users.api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/authContext";
import { Loading } from "../../General/loading";
import moment from "moment";
import { DateBox } from "devextreme-react";
import { useEffect } from "react";

function RecordingUploader() {
  const filesRef = React.useRef();
  const [files, setFiles] = React.useState([]);
  const [filesUploaded, setFilesUploaded] = React.useState(false);
  let [loading, setLoading] = React.useState(false);
  const [translatorMultiplier, setTranslatorMultiplier] = React.useState(1);
  const [reviewerMultiplier, setReviewerMultiplier] = React.useState(1);
  const [client, setClient] = React.useState("");
  const [translator, setTranslator] = React.useState("");
  const [reviewer, setReviewer] = React.useState("");
  const [dueDate, setDueDate] = React.useState(
    moment().add(1, "d").format("YYYY-MM-DDT15:00:00")
  );
  const [maxDueDate, setMaxDueDate] = React.useState(
    moment().add(1, "d").format("YYYY-MM-DDT23:00:00")
  );
  const [publicationDate, setpublicationDate] = React.useState(
    moment().add(1, "d").format("YYYY-MM-DD")
  );
  const [clients, setClients] = React.useState([]);
  const [translators, setTranslators] = React.useState([]);
  const [reviewers, setReviewers] = React.useState([]);
  const [loadingMessage, setLoadingMessage] = React.useState("0%");
  const [filesMetadata, setFilesMetadata] = React.useState([]);
  const { getToken, logout } = React.useContext(AuthContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    updateClientes();
    updateReviewers();
    updateTranslators();
  }, []);

  async function updateClientes() {
    getClients(getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve client's information due to an internal server error."
        );
      setClients(response.body);
    });
  }

  async function updateTranslators() {
    getTranslators(getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve translator's information due to an internal server error."
        );
      setTranslators(response.body);
    });
  }

  async function updateReviewers() {
    getReviewers(getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve reviewer's information due to an internal server error."
        );
      setReviewers(response.body);
    });
  }

  function getFiles() {
    if (!files) return [];
    const namesArray = [];
    for (let t = 0; t < files.length; t++) {
      namesArray.push(files[t].name);
    }
    return namesArray;
  }

  function clearFileSelection() {
    try {
      setFiles([]);
    } catch (error) {
      toast.error("There was an error, please reload the page to continue");
    }
  }

  function filesAreSelected() {
    return files.length !== 0;
  }

  function isAudioFile(type) {
    return type.includes("audio");
  }

  function getAudioType(filename) {
    const filenameSplit = filename.split(".");
    const format = filenameSplit[filenameSplit.length - 1];
    if (format === "wav") {
      return "audio/wave";
    }
    return "audio/mpeg";
  }

  function validAudioFiles() {
    for (let t = 0; t < files.length; t++) {
      if (!isAudioFile(files[t].type)) {
        return false;
      }
    }
    return true;
  }

  async function askForPresignedUrl(file) {
    return new Promise(async (resolve, reject) => {
      try {
        const presignedUrlResponse = await getPresignedURL(
          {
            name: file.name,
            ContentType: getAudioType(file.name),
          },
          getToken()
        );

        if (presignedUrlResponse.status !== 200) {
          toast.error(`Error uploading ${file.name}`);
          reject();
        }

        resolve(presignedUrlResponse.body.url);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function uploadFileToS3(file, url, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const uploadSuccess = await uploadRecordings(
          file,
          url,
          getAudioType(file.name)
        );

        if (!uploadSuccess) {
          toast.error(`Error uploading ${file.name}`);
          reject();
        }
        setFilesMetadata((prevState) => {
          const newFileMetadata = getAudioFileMetadata(id, file);
          return [...prevState, newFileMetadata];
        });
        modifyAudioDuration(file, id);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  function getUploadPercentage(uploadResponses) {
    return ((uploadResponses.length / files.length) * 100).toFixed(0) + "%";
  }

  async function uploadFiles() {
    setFilesUploaded(false);
    if (!filesAreSelected()) {
      return toast.error("Please first choose the file(s)...");
    }
    if (!validAudioFiles) {
      return toast.error("You are only allowed to upload audio files!");
    }
    setFilesMetadata([]);
    const uploadResponses = [];
    setLoading(true);
    for (let t = 0; t < files.length; t++) {
      try {
        const presignedUrl = await askForPresignedUrl(files[t]);
        await uploadFileToS3(files[t], presignedUrl, t);
        uploadResponses.push(true);
        setLoadingMessage(getUploadPercentage(uploadResponses));
      } catch (error) {
        console.error(error);
        uploadResponses.push(false);
        continue;
      } finally {
        if (uploadResponses.length === files.length) {
          toast.success(
            `Successfully uploaded ${
              uploadResponses.filter(Boolean).length
            } files!`
          );
          setTimeout(() => {            
            triggerFilesUploadedConfiguration();
          }, 1000);
        }
      }
    }
  }

  function getAudioFileMetadata(id, file) {
    return {
      id,
      name: file.name,
      link: `${S3_BUCKET}${file.name.replaceAll(" ", "+")}`,
      duration: 0,
      minutos: 0,
      segundos: 0,
      convict: file.name.split("-")[1],
      year: parseInt(`20${file.name.split("-")[0]}`),
      month: parseInt(file.name.split("-")[2]?.substring(0, 2)),
      day: parseInt(file.name.split("-")[2]?.substring(2, 4)),
      state: ASSIGNED,
      translatorMultiplier,
      reviewerMultiplier,
      dueDate,
      maxDueDate,
      publicationDate,
      client,
      translator,
      reviewer,
    };
  }

  function modifyAudioDuration(file, id) {
    const url = URL.createObjectURL(file);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.preload = "auto";

    // get video/audio duration when it's available
    audio.addEventListener("loadedmetadata", () => {
      setFilesMetadata((prevState) => {
        const newFilesMetadata = [...prevState];
        if (newFilesMetadata[id]) {
          const duration = audio.duration * 0.998865;
          newFilesMetadata[id].minutos = Math.trunc(duration / 60);
          newFilesMetadata[id].segundos = Math.trunc(duration % 60);
        }
        return newFilesMetadata;
      });
    });
  }

  function triggerFilesUploadedConfiguration() {
    setLoading(false);
    setFilesUploaded(true);
  }

  function updateFilesDisplay() {
    const files = filesRef.current.files;
    setFiles(files);
  }
  if (!filesUploaded) {
    return (
      <div>
        <RecordingsTitle />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              id="translatorMultiplier"
              label="Translator Multiplier"
              variant="outlined"
              type="number"
              fullWidth
              value={translatorMultiplier}
              onChange={(e) => setTranslatorMultiplier(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="reviewerMultiplier"
              label="Reviewer Multiplier"
              variant="outlined"
              type="number"
              fullWidth
              value={reviewerMultiplier}
              onChange={(e) => setReviewerMultiplier(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <DateBox
              height="45px"
              label="Publication Date: "
              value={publicationDate}
              onValueChanged={(e) => setpublicationDate(e.value)}
              type="date"
            />
          </Grid>
          <Grid item xs={6}>
            <DateBox
              height="45px"
              label="Due Date: "
              value={dueDate}
              onValueChanged={(e) => setDueDate(e.value)}
              type="datetime"
            />
            {/* <TextField
              id="dueDate"
              label="Due Date"
              variant="outlined"
              type="datetime-local"
              fullWidth
              value={dueDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setDueDate(e.target.value)}
            /> */}
          </Grid>
          <Grid item xs={6}>
            <DateBox
              height="45px"
              label="Max Due Date: "
              value={maxDueDate}
              onValueChanged={(e) => setMaxDueDate(e.value)}
              type="datetime"
            />
            {/* <TextField
              id="maxDueDate"
              label="Max Due Date"
              variant="outlined"
              type="datetime-local"
              fullWidth
              value={maxDueDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setMaxDueDate(e.target.value)}
            /> */}
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="client"
              select
              label="Client"
              value={client}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setClient(e.target.value)}
            >
              {clients.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                  {client.nombre}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="translator"
              select
              label="Translator"
              value={translator}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setTranslator(e.target.value)}
            >
              {translators.map((translator) => (
                <MenuItem key={translator.id} value={translator.id}>
                  {translator.nombreCompleto}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="reviewer"
              select
              label="Reviewer"
              value={reviewer}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setReviewer(e.target.value)}
            >
              {reviewers.map((reviewer) => (
                <MenuItem key={reviewer.id} value={reviewer.id}>
                  {reviewer.nombreCompleto}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {files.length > 0 && (
          <div>
            <ol>
              {getFiles().map((file) => {
                return <li key={file}>{file}</li>;
              })}
            </ol>
          </div>
        )}
        {files.length === 0 && (
          <div className="upload-btn-wrapper">
            <button className="btn">Upload files</button>
            <input
              ref={filesRef}
              type="file"
              name="myfile"
              onChange={updateFilesDisplay}
              multiple
            />
          </div>
        )}
        <br />
        {files.length > 0 && (
          <div>
            <Button
              variant="contained"
              color="error"
              style={{ width: 150, margin: "0 10px" }}
              onClick={clearFileSelection}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#aebd36",
                width: 150,
                margin: "0 10px",
              }}
              onClick={uploadFiles}
            >
              Submit
            </Button>
          </div>
        )}
        <Loading loading={loading} phrase={loadingMessage} />
      </div>
    );
  } else {
    return (
      <>
        <RecordingsTitle />
        <RecordingConfigurations
          files={filesMetadata}
          setFiles={setFilesMetadata}
        />
      </>
    );
  }
}

export { RecordingUploader };
