import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { width } from "@mui/system";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getPeriodos } from "../API/periodos.api";
import {
  getMinutesByReviewer,
  getMinutesByTranslator,
} from "../API/reportes.api";
import { getUsers } from "../API/users.api";
import { AuthContext } from "../Context/authContext.jsx";
import { ADMIN, REVIEWER, TRANSLATOR } from "../Utils/config";
import { getRolAsString } from "../Utils/utils";
import { MinutesWorkedTable } from "./minutesWorkedTable";

function MinutesWorkedMain() {
  const [usuarios, setUsuarios] = React.useState([]);
  const [usuariosFiltrados, setUsuariosFiltrados] = React.useState([]);
  const [rol, setRol] = React.useState("");
  const [periodos, setPeriodos] = React.useState([]);
  const [periodo, setPeriodo] = React.useState("");
  const [usuario, setUsuario] = React.useState("");
  const [recordings, setRecordings] = React.useState([]);
  const { getToken, logout, getRol } = React.useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    updateUsers();
    updatePeriodos();
  }, []);

  useEffect(() => {
    if (usuarios) {
      setUsuariosFiltrados(
        usuarios.filter((usuario) => usuario.RolUsuario === rol)
      );
    }
  }, [rol]);

  useEffect(() => {
    setRecordings([])
  }, [rol, usuario, periodo])

  const updateUsers = () => {
    getUsers(getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve users's information due to an internal server error."
        );
      setUsuarios(
        response.body.map((user) => {
          return {
            ...user,
            rol: getRolAsString(user.RolUsuario),
          };
        })
      );
    });
  };

  const updatePeriodos = () => {
    getPeriodos(getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve periods' information due to an internal server error."
        );
      setPeriodos(response.body);
    });
  };

  const search = () => {    
    const periodoCompleto = periodos.find(
      (periodoTmp) => periodoTmp.id === periodo
    );
    if(!periodoCompleto) return toast.error("Please select a valid period. ")
    if (rol === TRANSLATOR) {
      updateMinutesByTranslator(periodoCompleto);
    } else {
      updateMinutesByReviewer(periodoCompleto);
    }
  };

  const updateMinutesByTranslator = (periodoCompleto) => {
    getMinutesByTranslator(
      {
        fechaInicio: periodoCompleto.fechaInicio,
        fechaFin: periodoCompleto.fechaFin,
        userId: usuario,
      },
      getToken()
    ).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve periods' information due to an internal server error."
        );
      setRecordings(response.body);
    });
  };

  const updateMinutesByReviewer = (periodoCompleto) => {
    getMinutesByReviewer(
      {
        fechaInicio: periodoCompleto.fechaInicio,
        fechaFin: periodoCompleto.fechaFin,
        userId: usuario,
      },
      getToken()
    ).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve periods' information due to an internal server error."
        );
      setRecordings(response.body);
    });
  };

  return (
    <>
      <Grid container spacing={3} sx={{marginTop: "5px"}}>
        <Grid item xs={6}>
          <TextField
            id="role"
            select
            label="Role"
            value={rol}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setRol(e.target.value)}
          >            
            <MenuItem value={TRANSLATOR}>Translator</MenuItem>
            <MenuItem value={REVIEWER}>Reviewer</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="usuario"
            select
            label="Users"
            value={usuario}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setUsuario(e.target.value)}
          >
            {usuariosFiltrados.map((usuario) => (
              <MenuItem key={usuario.id} value={usuario.id}>
                {usuario.nombreCompleto}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="periodo"
            select
            label="Periods"
            value={periodo}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setPeriodo(e.target.value)}
          >
            {periodos.map((periodo) => (
              <MenuItem key={periodo.id} value={periodo.id}>
                {periodo.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#aebd36",
                width: "200px",
              }}
              onClick={search}
            >
              Search...
            </Button>
          </div>
        </Grid>
      </Grid>
      <div style={{ marginTop: "40px" }}>
        <MinutesWorkedTable recordings={recordings} />
      </div>
    </>
  );
}

export { MinutesWorkedMain };
