import { HOST } from "../Utils/config";
import { makeResponse } from "../Utils/fetchResponses";
import { getToken } from "../Utils/security";
const originalFetch = require("isomorphic-fetch");
const fetch = require("fetch-retry")(originalFetch, {
  retries: 5,
  retryDelay: 800,
});

async function getDisponibilidad(fecha) {
  try {
    const response = await fetch(
      `${HOST}/api/v1/disponibilidad?fecha=${fecha}`,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function getDisponibilidades(fecha) {
  try {
    const response = await fetch(`${HOST}/api/v1/disponibilidad/completa?fecha=${fecha}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function postDisponibilidad(disponibilidad) {
  try {
    const response = await fetch(`${HOST}/api/v1/disponibilidad`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(disponibilidad),
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(201, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function putDisponibilidad(disponibilidad, id) {
  try {
    const response = await fetch(
      `${HOST}/api/v1/disponibilidad/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(disponibilidad),
      }
    );
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function deleteAvailability(id) {
  try {
    const response = await fetch(`${HOST}/api/v1/disponibilidad/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

export {
  getDisponibilidad,
  getDisponibilidades,
  postDisponibilidad,
  putDisponibilidad,
  deleteAvailability
};
