import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { updatePassword } from "../../API/users.api";
import { AuthContext } from "../../Context/authContext";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "15px",
  boxShadow: 24,
};

function ChangePasswordModal({ restartPassword, message = "" }) {
  const [open, setOpen] = React.useState(false);
  const { getToken, updateCambioClaveToFalse } = React.useContext(AuthContext);
  const [previousPassword, setPreviousPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = React.useState("");

  useEffect(() => {
    setOpen(restartPassword);
  }, [restartPassword]);

  const isFormComplete = () => {
    return (
      newPassword !== "" &&
      previousPassword !== "" &&
      newPasswordConfirmation !== ""
    );
  };

  const newPasswordsAreEqual = () => {
    return newPassword === newPasswordConfirmation;
  };

  const save = () => {
    if (!isFormComplete()) {
      return toast.error("You need to fill the entire form");
    }
    if (!newPasswordsAreEqual()) {
      return toast.error(
        "New password and confirmation password are not the same"
      );
    }
    updatePassword({ previousPassword, newPassword }, getToken()).then(
      (response) => {
        if (response.status === 200) {
          toast.success("Password changed successfully");
          updateCambioClaveToFalse();
          setOpen(false);
        } else if (response.status === 401) {
          toast.error("Previous password is incorrect");
        } else if (response.status === 403) {
          toast.error("Invalid session. Please logout and log back in. ");
        } else {
          toast.error("There was an internal server error. Please try again");
        }
      }
    );
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 10px",
            borderRadius: "15px",
            padding: "30px 50px",
            backgroundColor: "white",
            maxWidth: 900,
          }}
        >
          <h2 style={{ textAlign: "left" }}>Change Password</h2>
          <p>{message}</p>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="previousPassword"
                label="Previous Password"
                variant="outlined"
                type="password"
                fullWidth
                value={previousPassword}
                onChange={(e) => setPreviousPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="newPassword"
                label="New Password"
                variant="outlined"
                fullWidth
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="newPasswordConfiramtion"
                label="Confirm New Password"
                variant="outlined"
                type="password"
                fullWidth
                value={newPasswordConfirmation}
                onChange={(e) => setNewPasswordConfirmation(e.target.value)}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#aebd36",
              }}
              onClick={save}
            >
              Change Password
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export { ChangePasswordModal };
