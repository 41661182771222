import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { RecordingsList } from "./recordingsList";
import { RecordingUploader } from "./recordingUploader";
import { AuthContext } from "../../Context/authContext";
import { REVIEWER, TRANSLATOR } from "../../Utils/config";
import { TranslatorRecordings } from "../../Translator/translatorRecordingsList";

function RecordingsOperationSelection() {
  const location = useLocation().pathname;
  const { getRol } = React.useContext(AuthContext);

  if (location === "/home") {
    return <TranslatorRecordings />;
  } else if (location === "/home/recordings/upload") {
    return <RecordingUploader />;
  } else if (location.includes("/home/recordings/")) {
    return <Outlet />;
  }

  if (getRol() === TRANSLATOR || getRol() === REVIEWER) {
    return <TranslatorRecordings />;
  }
  return <RecordingsList />;
}

export { RecordingsOperationSelection };
