import { Button, Grid } from "@mui/material";
import { CheckBox, DateBox } from "devextreme-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getPeriodos } from "../API/periodos.api";
import {
  getBalanceGeneral,
  getMinutesByReviewer,
  getMinutesByTranslator,
} from "../API/reportes.api";
import { getUsers } from "../API/users.api";
import { AuthContext } from "../Context/authContext.jsx";
import { getRolAsString } from "../Utils/utils";
import moment from "moment";
import { BalanceGeneralTable } from "./clientsMinutesWorkedTable";
import { Loading } from "../General/loading";

function ClientsMinutesWorked() {
  const [date, setDate] = React.useState(new Date());
  const [recordings, setRecordings] = React.useState(new Date());
  const [loading, setLoading] = React.useState(false);
  const { getToken, logout, getRol } = React.useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (date) {
      updateRecordings();
    }
  }, [date]);

  const updateRecordings = () => {
    setLoading(true);
    const firstDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      1
    ).toISOString();
    const lastDay = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).toISOString();
    getBalanceGeneral(
      moment(firstDay).format("YYYY-MM-DD"),
      moment(lastDay).format("YYYY-MM-DD"),
      getToken()
    ).then((response) => {
      setLoading(false);
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve periods' information due to an internal server error."
        );
      setRecordings(response.body);      
    });
  };

  return (
    <>
      <Grid container spacing={3} sx={{ marginTop: "5px", marginLeft: "2px", width: "98%" }}>
        <Grid item xs={3}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <b style={{ marginRight: "10px" }}>Month:</b>
            <DateBox
              value={date}
              placeholder="Select a month"
              useMaskBehavior={true}
              type="date"
              displayFormat={"MMMM, yyyy"}
              onValueChanged={(e) => setDate(e.value)}
            />
          </div>
        </Grid>
      </Grid>      
      <div style={{ marginTop: "40px" }}>
        <BalanceGeneralTable recordings={recordings} />
      </div>
      <Loading loading={loading} phrase={"Loading..."} />
    </>
  );
}

export { ClientsMinutesWorked };
