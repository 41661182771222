import React from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Pager,
  Paging,
  GroupPanel,
  TotalItem,
  GroupItem,
  Summary,
  Grouping
} from "devextreme-react/data-grid";
import { ADMIN } from "../Utils/config";
import { AuthContext } from "../Context/authContext";

function MinutesWorkedTable({ recordings }) {
  const { getRol } = React.useContext(AuthContext);
  const [expandAll, setExpandAll] = React.useState(false);

  return (
    <>
      <DataGrid
        dataSource={recordings}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="nextColumn"
        // defaultColumns={columns}
        // rowRender={plantillaFila}
      >
        <GroupPanel visible={getRol() === ADMIN} />
        <Grouping autoExpandAll={false} />
        <Column dataField="id" caption="id" dataType="number" visible={false} />
        <Column
          visible={getRol() === ADMIN}
          dataField="name"
          caption="Name"
          dataType="string"
          allowSorting={true}
          allowFiltering={true}
          minWidth={220}
          allowResizing
        />
        <Column
          dataField="publicationDate"
          caption="Publication Date"
          dataType="date"
          format="longDate"
          allowFiltering={getRol() === ADMIN}
          allowSorting={getRol() === ADMIN}
          groupIndex={getRol() === ADMIN ? 0 : -1}
        />
        <Column
          dataField="Minutos"
          caption="Duration"
          dataType="number"
          allowFiltering={getRol() === ADMIN}
          allowSorting={getRol() === ADMIN}
          cellRender={(rowInfo) => {
            return (
              <>
                {Math.trunc(rowInfo.data.Minutos)} min{" "}
                {(60 * (rowInfo.data.Minutos % 1)).toFixed(0)} s
              </>
            );
          }}
          allowResizing
        />
        <Column
          dataField="Pago"
          caption="Payment"
          dataType="number"
          format="currency"
          allowFiltering={getRol() === ADMIN}
          allowSorting={getRol() === ADMIN}
          cellRender={(rowInfo) => `$${rowInfo.data.Pago.toFixed(2)}`}
        />

        <FilterRow visible={getRol() === ADMIN} />
        <FilterPanel visible={false} />

        <HeaderFilter visible={true} />

        <Paging defaultPageSize={50} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[50, 100, 150, 200]}
          showInfo={true}
        />

        <Summary>
          <TotalItem
            column="Pago"
            summaryType="sum"
            valueFormat="currency"
            customizeText={(data) => `$${data.value.toFixed(2).toLocaleString("en")}`}
          />
          <GroupItem
            column="Pago"
            summaryType="sum"
            valueFormat="currency"
            howInGroupFooter={false}
            alignByColumn={true}
            customizeText={(data) => `$${data.value.toLocaleString("en")}`}
          />
          <TotalItem
            column="Minutos"
            summaryType="sum"
            valueFormat="number"
            customizeText={(data) =>
              `${Math.trunc(data.value)} min ${(60 * (data.value % 1)).toFixed(
                0
              )} s `
            }
          />
          <GroupItem
            column="Minutos"
            summaryType="sum"
            valueFormat="number"
            howInGroupFooter={false}
            alignByColumn={true}
            customizeText={(data) =>
              `${Math.trunc(data.value)} min ${(60 * (data.value % 1)).toFixed(
                0
              )} s `
            }
          />
        </Summary>
      </DataGrid>
    </>
  );
}

export { MinutesWorkedTable };
