const HOST = "https://lq05lxw5ka.execute-api.us-east-1.amazonaws.com"
//const HOST = "http://localhost:3000";
const DRAWER_COLOR = "#202e3c"
const S3_BUCKET = "https://traducciones-storage.s3.amazonaws.com/"
const S3_BUCKET_WORD_TRADUCTOR = "https://traducciones-storage-translator.s3.amazonaws.com/"
const S3_BUCKET_WORD_REVISOR = "https://traducciones-storage-reviewer.s3.amazonaws.com/"
const ASSIGNED = 1;
const ADMIN = 1;
const REVIEWER = 2; 
const TRANSLATOR = 3;

export { HOST, DRAWER_COLOR, S3_BUCKET, ASSIGNED, TRANSLATOR, REVIEWER, ADMIN, S3_BUCKET_WORD_TRADUCTOR, S3_BUCKET_WORD_REVISOR };
