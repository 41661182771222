import React from "react";
const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const getToken = () => {
    const storage = sessionStorage.getItem("user");
    if (storage) {
      return JSON.parse(storage).token;
    }
    return null;
  };

  const getRol = () => {
    const storage = sessionStorage.getItem("user");
    if (storage) {
      return JSON.parse(storage).rol;
    }
    return null;
  };

  const getSupervisor = () => {
    const storage = sessionStorage.getItem("user");
    if (storage) {
      return JSON.parse(storage).supervisor;
    }
    return null;
  };

  const getNombre = () => {
    const storage = sessionStorage.getItem("user");
    if (storage) {
      return JSON.parse(storage).nombre;
    }
    return null;
  };

  const getId = () => {
    const storage = sessionStorage.getItem("user");    
    if (storage) {
      return JSON.parse(storage).id;
    }
    return null;
  };

  const getUser = () => {
    const storage = sessionStorage.getItem("user");
    if (storage) {
      return JSON.parse(storage).email;
    }
    return null;
  };

  const cambioClave = () => {
    const storage = sessionStorage.getItem("user");
    if (storage) {
      return JSON.parse(storage).cambioClave;
    }
    return false;
  };

  const isAuthenticated = () => {
    const storage = sessionStorage.getItem("user");
    if (storage && JSON.parse(storage).token && JSON.parse(storage).rol) {
      return true;
    }
    return false;
  };

  const updateCambioClaveToFalse = () => {
    const storage = sessionStorage.getItem("user");
    const storageAsJson = JSON.parse(storage);
    storageAsJson.cambioClave = false;
    sessionStorage.setItem("user", JSON.stringify(storageAsJson));
  };

  const login = (data) => {
    sessionStorage.setItem("user", data);
  };

  const logout = () => {
    sessionStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        getToken,
        login,
        logout,
        getUser,
        getRol,
        getSupervisor,
        cambioClave,
        updateCambioClaveToFalse,
        getNombre,
        getId
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
