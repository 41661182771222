import { ADMIN, REVIEWER, TRANSLATOR } from "./config";

function getRolAsString(id, supervisor) {
  const roles = {
    1: "Admin",
    2: `Reviewer${supervisor ? "- Sup." : ""}`,
    3: "Translator",
  };
  return roles[id];
}

const getPath = (rol, supervisor = false) => {
  if (supervisor) return "translations";
  switch (rol) {
    case ADMIN:
      return "translations";
    case TRANSLATOR:
      return "mytranslations";
    case REVIEWER:
      return "myreviews";
    default:
      return "none";
  }
};

export { getRolAsString, getPath };
