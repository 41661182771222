import Modal from "../../Custom/modal";
import React from "react";
import { ClientesForm } from "./clientsForm";

function ClientesModal({ open, setOpen, updateClientes, clienteActivo }) {
  function handleCloseModal() {
    setOpen(false);
  }
  return (
    <Modal open={open} handleClose={handleCloseModal} title="New Client">
      <ClientesForm
        setOpen={setOpen}
        updateClientes={updateClientes}
        clienteActivo={clienteActivo}
      />
    </Modal>
  );
}

export { ClientesModal };
