import React from "react";
import { useNavigate } from "react-router-dom";
import "./logoComponent.css";

function LogoComponent() {  
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate(`/home`);
  };
  
  return (
    <div className="logo-container" onClick={handleLogoClick} style={{cursor: "pointer"}}>
      <div className="logo"></div>
    </div>
  );
}

export { LogoComponent };
