import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Format,
  Label,
  Legend,
  Series,
  Tooltip,
  ValueAxis,
} from "devextreme-react/chart";
import React, { useEffect } from "react";

function MinutesChart({ data }) {
  function customizeMoneyText({ valueText }) {
    return `${valueText} min`;
  }

  function customizeMoneyText({ valueText }) {
    return `$${valueText}`;
  }

  function customizePaymentTooltip(pointInfo) {    
    return {
      html: `<b>Date:</b> ${
        pointInfo.argumentText
      }<br /><b>Payment: </b>$${pointInfo.value.toFixed(
        2
      )}`,
    };
  }

  function customizeMinutesTooltip(pointInfo) {    
    return {
      html: `<b>Date:</b> ${
        pointInfo.argumentText
      }<br /><b>Duration:</b> ${Math.trunc(
        pointInfo.value
      )} min ${(60 * (pointInfo.value % 1)).toFixed(0)} seg`,
    };
  }

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
      }}
    >
      <Chart
        title="Minutes Worked"
        dataSource={data}
        palette="Pastel"
        id="chart"
      >
        <CommonSeriesSettings argumentField="publicationDate" />
        <Series
          name="Minutes Worked"
          valueField="Minutos"
          axis="frequency"
          type="bar"
        />
        <ArgumentAxis>
          <Label overlappingBehavior="stagger" />
        </ArgumentAxis>

        <ValueAxis
          name="frequency"
          position="left"
          tickInterval={20}
          showZero={true}
          valueMarginsEnabled={false}
        >
          <Label customizeText={customizeMoneyText} />
        </ValueAxis>

        <Tooltip customizeTooltip={customizeMinutesTooltip} enabled={true}/>

        <Legend verticalAlignment="bottom" horizontalAlignment="center" />
      </Chart>
      <Chart title="Payment" dataSource={data} palette="Material" id="chart">
        <CommonSeriesSettings argumentField="publicationDate" />
        <Series name="Payment" valueField="Pago" axis="frequency" type="bar" />
        <ArgumentAxis>
          <Label overlappingBehavior="stagger" />
        </ArgumentAxis>

        <ValueAxis
          name="frequency"
          position="left"
          tickInterval={20}
          showZero={true}
          valueMarginsEnabled={false}
        >
          <Label customizeText={customizeMoneyText} />
        </ValueAxis>

        <Tooltip enabled={true} customizeTooltip={customizePaymentTooltip} />

        <Legend verticalAlignment="bottom" horizontalAlignment="center" />
      </Chart>
      {/* <BarChart
        width={550}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="publicationDate" />
        <YAxis
          tickFormatter={(tick) => {
            return tick + " min";
          }}
          format={(value) => value + "min"}
        />
        <Tooltip customizeTooltip={customizeTooltip} />
        <Legend />
        <Bar dataKey="Minutos" name="Minutes Worked" fill="#eba11b" />
      </BarChart> */}
      {/*
      <BarChart
        width={550}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="publicationDate" />
        <YAxis
          tickFormatter={(tick) => {
            return "$" + tick;
          }}
        />
        <Tooltip />
        <Legend />

        <Bar dataKey="Pago" name="Payment" fill="#3562da" />
      </BarChart> */}
    </div>
  );
}

export { MinutesChart };
