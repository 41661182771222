import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { createUser, updateUser } from "../../API/users.api";
import { DataContext } from "../../Context/dataContext";
import { AuthContext } from "../../Context/authContext";
import { useNavigate } from "react-router-dom";
import { REVIEWER } from "../../Utils/config";

const roles = [
  { id: 1, name: "Admin" },
  { id: 2, name: "Reviewer" },
  { id: 3, name: "Translator" },
];

function CreateUserForm() {
  const { activeUser, closeModal, updateUsers } = React.useContext(DataContext);
  const { getToken, logout } = React.useContext(AuthContext);
  const [fullName, setFullName] = React.useState(
    activeUser?.nombreCompleto || ""
  );
  const [email, setEmail] = React.useState(activeUser?.email || "");
  const [phone, setPhone] = React.useState(activeUser?.telefonos || "");
  const [accountNo, setAccountNo] = React.useState(activeUser?.noCuenta || "");
  const [password, setPassword] = React.useState("Translate");
  const [passwordConfirmation, setPasswordConfirmation] =
    React.useState("Translate");
  const [accountType, setAccountType] = React.useState(
    activeUser?.tipoCuenta || ""
  );
  const [bank, setBank] = React.useState(activeUser?.banco || "");
  const [rol, setRol] = React.useState(activeUser?.RolUsuario || 1);
  const [salary, setSalary] = React.useState(activeUser?.pagoMinuto || 0);
  const [active, setActive] = React.useState(activeUser?.activo || false);
  const [supervisor, setSupervisor] = React.useState(
    activeUser?.supervisor || false
  );
  const [restart, setRestart] = React.useState(
    activeUser?.cambioClave || false
  );
  const navigate = useNavigate();

  React.useEffect(() => {    
    if (rol !== REVIEWER) {
      setSupervisor(false);
    }
  }, [rol]);

  const handleRolChange = (event) => {
    setRol(event.target.value);
  };

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlesetPhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleAccountNoChange = (event) => {
    setAccountNo(event.target.value);
  };

  const handleAccountTypeChange = (event) => {
    setAccountType(event.target.value);
  };

  const handleBankChange = (event) => {
    setBank(event.target.value);
  };

  const handleSalaryChange = (event) => {
    setSalary(event.target.value);
  };

  const handleActiveChange = (event) => {
    setActive(!active);
  };

  const handleRestartChange = (event) => {
    setRestart(!restart);
  };

  const createNewUser = () => {
    if (credentialsInvalid())
      return toast.error("It's necessary to fill the entire form");
    if (!passwordsMatch())
      return toast.error("Password and confirmation password must be the same");
    const user = {
      email: email,
      password: password,
      nombreCompleto: fullName,
      telefonos: phone,
      pagoMinuto: salary,
      noCuenta: accountNo,
      banco: bank,
      tipoCuenta: accountType,
      RolUsuario: rol,
      activo: active,
      cambioClave: restart,
      supervisor
    };
    createUser(user, getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "There was an error creating the user. Please try again."
        );
      toast.success("User created successfully");
      closeModal();
      updateUsers();
    });
  };

  const updateUserLocal = () => {
    if (credentialsInvalid())
      return toast.error("It's necessary to fill the entire form");
    const user = {
      email: email,
      nombreCompleto: fullName,
      telefonos: phone,
      pagoMinuto: salary,
      noCuenta: accountNo,
      banco: bank,
      tipoCuenta: accountType,
      RolUsuario: rol,
      activo: active,
      cambioClave: restart,
      supervisor
    };
    if (restart) {
      if (!passwordsMatch()) {
        return toast.error(
          "Password and confirmation password must be the same"
        );
      }
      user.password = password;
    }
    updateUser(user, activeUser.id, getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "There was an error updating the user. Please try again."
        );
      toast.success("User modified successfully");
      closeModal();
      updateUsers();
    });
  };

  const credentialsInvalid = () => {
    return (
      fullName === "" ||
      email === "" ||
      phone === "" ||
      accountNo === "" ||
      accountType === "" ||
      bank === "" ||
      salary === null ||
      password === "" ||
      passwordConfirmation === ""
    );
  };

  const passwordsMatch = () => {
    return password === passwordConfirmation;
  };

  return (
    <section
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 10px",
          borderRadius: "15px",
          padding: "30px 50px",
          maxWidth: 900,
        }}
      >
        <h2 style={{ textAlign: "left" }}>Create new user</h2>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              id="name"
              label="Full Name"
              variant="outlined"
              fullWidth
              value={fullName}
              onChange={handleFullNameChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              type="email"
              fullWidth
              value={email}
              onChange={handleEmailChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="cellphone"
              label="Phone number"
              variant="outlined"
              fullWidth
              value={phone}
              onChange={handlesetPhoneChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="account"
              label="Account Number"
              variant="outlined"
              fullWidth
              value={accountNo}
              onChange={handleAccountNoChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="accounttype"
              label="Account Type"
              variant="outlined"
              fullWidth
              value={accountType}
              onChange={handleAccountTypeChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="bank"
              label="Bank"
              variant="outlined"
              fullWidth
              value={bank}
              onChange={handleBankChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="rol-select"
              select
              label="Role"
              value={rol}
              fullWidth
              onChange={handleRolChange}
            >
              {roles.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="standard-adornment-amount">
                Salary/min
              </InputLabel>
              <OutlinedInput
                type="number"
                id="filled-adornment-amount"
                fullWidth
                placeholder="Salary/minute"
                value={salary}
                onChange={handleSalaryChange}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Salary/min"
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: `${restart || !activeUser ? "block" : "none"}` }}
          >
            <TextField
              id="pass"
              label="Default Password"
              variant="outlined"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: `${restart || !activeUser ? "block" : "none"}` }}
          >
            <TextField
              id="passConf"
              label="Default Password Confirmation"
              variant="outlined"
              type="password"
              fullWidth
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={active}
                  value={active}
                  onChange={handleActiveChange}
                  color="default"
                />
              }
              label="Active user?"
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={restart}
                  value={restart}
                  onChange={handleRestartChange}
                  color="default"
                />
              }
              label="Restart Password?"
            />
          </Grid>
          <Grid item xs={4} display={rol === REVIEWER ? "block" : "none"}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={supervisor}
                  value={supervisor}
                  onChange={() => setSupervisor(!supervisor)}
                  color="default"
                />
              }
              label="Supervisor?"
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            padding: "20px 100px 0px 100px",
          }}
        >
          <Button
            variant="contained"
            color="error"
            style={{ width: 150 }}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#aebd36",
              width: 150,
              display: `${activeUser ? "none" : "block"}`,
            }}
            onClick={createNewUser}
          >
            Create User!
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#aebd36",
              width: 150,
              display: `${activeUser ? "block" : "none"}`,
            }}
            onClick={updateUserLocal}
          >
            Update User!
          </Button>
        </div>
      </div>
    </section>
  );
}

export { CreateUserForm };
