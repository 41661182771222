import React, { useEffect } from "react";
import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import moment from "moment";
import { AuthContext } from "../../Context/authContext";
import {
  getDisponibilidad,
  postDisponibilidad,
  putDisponibilidad,
} from "../../API/availability.api";
import { toast } from "react-toastify";
import { getConfig } from "../../API/config.api";

const CONFIG_AVAILABILITY = 1;

function getNextWeekDate() {
  const date = new Date();
  const differenceInDays = 8 - date.getDay();
  date.setDate(date.getDate() + differenceInDays);
  return date;
}

function getDayAsString(day) {
  const daysObj = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  };
  return daysObj[day];
}

function AvailabilityInput({ text, state, setState }) {
  return (
    <>
      <Grid item xs={2} />
      <Grid item xs={8}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Typography variant="h6" gutterBottom component="div">
            {text}
          </Typography>
          <OutlinedInput
            id="outlined-adornment-weight"
            endAdornment={<InputAdornment position="end">min</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            value={state}
            onChange={(e) => setState(e.target.value)}
            type="number"
            //disabled={!isValidToModify()}
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </div>
      </Grid>
      <Grid item xs={2} />
      <Divider sx={{ width: "100%", margin: "7px 0px" }} />
    </>
  );
}

function AvailabilityUsers() {
  const [id, setId] = React.useState(-1);
  const [martes, setMartes] = React.useState(0);
  const [miercoles, setMiercoles] = React.useState(0);
  const [jueves, setJueves] = React.useState(0);
  const [viernes, setViernes] = React.useState(0);
  const [sabado, setSabado] = React.useState(0);
  const [domingo, setDomingo] = React.useState(0);
  const [lunes, setLunes] = React.useState(0);

  const [initialDay, setInitialDay] = React.useState(-1);
  const [initialHour, setInitialHour] = React.useState(
    new Date(1997, 11, 11, 12, 0)
  );
  const [lastDay, setlastDay] = React.useState(-1);
  const [lastHour, setlastHour] = React.useState(new Date(1997, 11, 11, 13, 0));

  useEffect(() => {
    getConfig(CONFIG_AVAILABILITY).then((response) => {
      if (response.status === 200) {
        const availabilityConfig = JSON.parse(response.body.valor);        
        if (availabilityConfig) {
          setInitialDay(availabilityConfig.initialDay);
          setInitialHour(
            new Date(
              1997,
              11,
              11,
              availabilityConfig.initialHour,
              availabilityConfig.initialMinutes
            )
          );
          setlastDay(availabilityConfig.lastDay);
          setlastHour(
            new Date(
              1997,
              11,
              11,
              availabilityConfig.lastHour,
              availabilityConfig.lastMinutes
            )
          );
        }
      } else {
        toast.error("No se pudo recuperar la disponibilidad");
      }
    });
  }, []);

  useEffect(() => {
    if (initialDay !== -1 && lastDay !== -1) {
      if (isValidToModify()) {
        loadData();
      }
    }
  }, [initialDay, initialHour, lastDay, lastHour]);

  function isValidToModify() {
    const todaysDate = new Date();
    const initialDate = new Date();
    let cont = 0;
    if (initialDay < initialDate.getDay()) {
      while (initialDay < initialDate.getDay()) {
        initialDate.setDate(initialDate.getDate() - 1);
        if (cont++ > 500) break;
      }
    }
    cont = 0;
    if (initialDay > initialDate.getDay()) {
      while (initialDay > initialDate.getDay()) {
        initialDate.setDate(initialDate.getDate() + 1);
        if (cont++ > 500) break;
      }
    }
    initialDate.setHours(initialHour.getHours(), initialHour.getMinutes(), 0);
    const lastDate = new Date(initialDate.getTime());    
    while (lastDate.getDay() < lastDay) {
      lastDate.setDate(lastDate.getDate() + 1);
    }
    lastDate.setHours(lastHour.getHours(), lastDate.getMinutes(), 0);    
    if (todaysDate > initialDate && todaysDate < lastDate) {
      return true;
    }
    return false;
  }

  function getOpeningDate() {
    return `The availability form will be available on ${getDayAsString(
      initialDay
    )} after ${initialHour.getHours()}:${
      (initialHour.getMinutes() < 10 ? "0" : "") + initialHour.getMinutes()
    }`;
  }

  function loadData() {
    const fecha = moment(getNextWeekDate()).format("YYYY-MM-DD");
    getDisponibilidad(fecha).then((response) => {
      if (response.status === 200) {
        setId(response.body.id);
        setLunes(response.body.lunes);
        setMartes(response.body.martes);
        setMiercoles(response.body.miercoles);
        setJueves(response.body.jueves);
        setViernes(response.body.viernes);
        setSabado(response.body.sabado);
        setDomingo(response.body.domingo);
      } else {
        setId(-1);
      }
    });
  }

  function save() {
    const disponibilidad = {
      fecha: moment(getNextWeekDate()).format("YYYY-MM-DD"),
      martes,
      miercoles,
      jueves,
      viernes,
      sabado,
      domingo,
      lunes,
    };
    if (id === -1) {
      postDisponibilidad(disponibilidad).then((response) => {
        if (response.status !== 201)
          return toast.error("There was an error saving the availability");
        loadData();
        return toast.success("Availability saved successfully");
      });
    } else {
      // Es un update
      putDisponibilidad(disponibilidad, id).then((response) => {
        if (response.status !== 200)
          return toast.error("There was an error saving the availability");
        return toast.success("Availability saved successfully");
      });
    }
  }

  if (!isValidToModify()) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <h1>{getOpeningDate()}</h1>
        <img src="/later.jpg" alt="Come back later img" />
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 10px",
          borderRadius: "15px",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <EventAvailableIcon fontSize="large" sx={{ margin: "0 2px" }} />
        <h1 style={{ margin: 10 }}>Availability</h1>
      </div>
      <Grid container spacing={0} sx={{ marginTop: "0px" }}>
        <AvailabilityInput
          text="Monday 5:00 PM to Tuesday 3:00 PM:"
          state={martes}
          setState={setMartes}
        />
        <AvailabilityInput
          text="Tuesday 5:00 PM to Wednesday 3:00 PM:"
          state={miercoles}
          setState={setMiercoles}
        />
        <AvailabilityInput
          text="Wednesday 5:00 PM to Thursday 3:00 PM:"
          state={jueves}
          setState={setJueves}
        />
        <AvailabilityInput
          text="Thursday 5:00 PM to Friday 3:00 PM:"
          state={viernes}
          setState={setViernes}
        />
        <AvailabilityInput
          text="Friday 5:00 PM to Saturday 3:00 PM:"
          state={sabado}
          setState={setSabado}
        />
        <AvailabilityInput
          text="Friday 5:00 PM to Sunday 3:00 PM:"
          state={domingo}
          setState={setDomingo}
        />
        <AvailabilityInput
          text="Friday 5:00 PM to Monday 3:00 PM:"
          state={lunes}
          setState={setLunes}
        />

        <Grid
          item
          xs={12}
          lg={12}
          sm={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#69bd4b",
              color: "white",
              marginRight: "20px",
              width: "100px",
            }}
            onClick={() => save()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export { AvailabilityUsers, getNextWeekDate, getDayAsString };
