import React, { useEffect } from "react";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import moment from "moment";
import {
  deleteAvailability,
  getDisponibilidades,
  putDisponibilidad,
} from "../../API/availability.api";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
  TotalItem,
  Summary,
} from "devextreme-react/data-grid";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AvailabilityConfigs } from "./availabilityConfigs";
import { Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmationDialog } from "../../General/confirmationDialog";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/authContext";

let availabilityId = -1;

function AvailabilityAdmin() {
  const [initialDate, setInitialDate] = React.useState(null);
  const [availabilityTrad, setAvailabilityTrad] = React.useState([]);
  const [availabilityRev, setAvailabilityRev] = React.useState([]);
  const navigate = useNavigate();
  const { logout } = React.useContext(AuthContext);

  useEffect(() => {
    getCorrespondingMonday();
  }, []);

  useEffect(() => {
    updateAvailability();
  }, [initialDate]);

  function updateAvailability() {
    if (initialDate) {
      const date = moment(initialDate).format("YYYY-MM-DD");
      getDisponibilidades(date).then((response) => {
        if (response.status === 403) {
          logout();
          navigate("/");
          return toast.error("You are not allowed to see this content.");
        }
        if (response.status === 500)
          return toast.error(
            "Couldn't retrieve recordings due to an internal server error. Please try again later."
          );

        if (response.status === 200) {
          setAvailabilityRev(
            response.body
              .filter((element) => element.usuario.RolUsuario === 2)
              .map((element) => {
                return {
                  ...element,
                  nombreUsuario: element.usuario.nombreCompleto,
                };
              })
          );
          setAvailabilityTrad(
            response.body
              .filter((element) => element.usuario.RolUsuario === 3)
              .map((element) => {
                return {
                  ...element,
                  nombreUsuario: element.usuario.nombreCompleto,
                };
              })
          );
        }
      });
    }
  }

  function getCorrespondingMonday() {
    const date = new Date();
    if (date.getDay() === 6) {
      date.setDate(date.getDate() + 2);
    }
    if (date.getDay() === 0) {
      date.setDate(date.getDate() + 1);
    } else {
      const differenceInDays = date.getDay() - 1;
      date.setDate(date.getDate() - differenceInDays);
    }
    setInitialDate(date);
  }

  function getDateToDisplay() {
    if (!initialDate) return "";
    const lastDate = new Date(initialDate.getTime());
    lastDate.setDate(lastDate.getDate() + 7);
    return `From ${moment(initialDate).format("DD-MM-YYYY")} To ${moment(
      lastDate
    ).format("DD-MM-YYYY")}`;
  }

  function goBackSevenDays() {
    const date = new Date(initialDate.getTime());
    date.setDate(date.getDate() - 7);
    setInitialDate(date);
  }

  function MoveOnSevenDays() {
    const date = new Date(initialDate.getTime());
    date.setDate(date.getDate() + 7);
    setInitialDate(date);
  }

  return (
    <div>
      <div
        style={{
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 10px",
          borderRadius: "15px",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <EventAvailableIcon fontSize="large" sx={{ margin: "0 2px" }} />
        <h1 style={{ margin: 10 }}>Availability</h1>
      </div>
      <AvailabilityConfigs />
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <ArrowBackIcon sx={{ cursor: "pointer" }} onClick={goBackSevenDays} />
          <h3 style={{ textAlign: "left", margin: "0px 10px" }}>
            {getDateToDisplay()}
          </h3>
          <ArrowForwardIcon
            sx={{ cursor: "pointer" }}
            onClick={MoveOnSevenDays}
          />
        </div>
        <h3 style={{ textAlign: "center", marginTop: "10px" }}>Traductores </h3>
        <AvailabilityTable
          rows={availabilityTrad}
          update={updateAvailability}
        />
        <h3 style={{ textAlign: "center", marginTop: "10px" }}>Revisores </h3>
        <AvailabilityTable rows={availabilityRev} update={updateAvailability} />
      </div>
    </div>
  );
}

function AvailabilityTable({ rows, update }) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const cellOpciones = (rowInfo) => {
    return (
      <Tooltip title="Delete" style={{ marginRight: 10, cursor: "pointer" }}>
        <DeleteIcon
          style={{ cursor: "pointer", color: "#f01e0f" }}
          onClick={() => {
            availabilityId = rowInfo.data.id;
            setOpenDialog(true);
          }}
        />
      </Tooltip>
    );
  };

  function deleteAvailabilityLocal() {
    const id = availabilityId;
    availabilityId = -1;
    deleteAvailability(id).then((response) => {
      if (response.status === 200) {
        toast.success("Registro eliminado exitosamente!");
        update();
        setOpenDialog(false);
      } else {
        toast.error("Hubo un problema al eliminar el registro");
      }
    });
  }

  return (
    <DataGrid
      dataSource={rows}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnResizingMode="nextColumn"
      // defaultColumns={columns}
      // rowRender={plantillaFila}
    >
      <Column
        dataField="nombreUsuario"
        caption="Usuario"
        dataType="string"
        visible={true}
      />
      <Column
        dataField="martes"
        caption="Lunes a Martes"
        dataType="number"
        allowSorting={true}
        allowResizing
      />
      <Column
        dataField="miercoles"
        caption="Martes a Miércoles"
        dataType="number"
        allowSorting={true}
        allowResizing
      />
      <Column
        dataField="jueves"
        caption="Miércoles a Jueves"
        dataType="number"
        allowSorting={true}
        allowResizing
      />
      <Column
        dataField="viernes"
        caption="Jueves a Viernes"
        dataType="number"
        allowSorting={true}
        allowResizing
      />
      <Column
        dataField="sabado"
        caption="Viernes a Sábado"
        dataType="number"
        allowSorting={true}
        allowResizing
      />
      <Column
        dataField="domingo"
        caption="Viernes a Domingo"
        dataType="number"
        allowSorting={true}
        allowResizing
      />
      <Column
        dataField="lunes"
        caption="Viernes a Lunes"
        dataType="number"
        allowSorting={true}
        allowResizing
      />
      <Column
        caption="Opciones"
        allowFiltering={false}
        allowSorting={false}
        cellRender={cellOpciones}
        width={80}
      />

      {/* <FilterBuilderPopup position={filterBuilderPopupPosition} /> */}
      {/* <Scrolling mode="infinite" /> */}

      {/* <GroupPanel visible={true} /> */}
      {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
      {/* <Paging defaultPageSize={10} /> */}
      <Paging defaultPageSize={50} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[50, 100, 150, 200]}
        showInfo={true}
      />
      <Summary>
        <TotalItem
          column="martes"
          summaryType="sum"
          customizeText={(data) => `${data.value} min`}
        />
        <TotalItem
          column="miercoles"
          summaryType="sum"
          customizeText={(data) => `${data.value} min`}
        />
        <TotalItem
          column="jueves"
          summaryType="sum"
          customizeText={(data) => `${data.value} min`}
        />
        <TotalItem
          column="viernes"
          summaryType="sum"
          customizeText={(data) => `${data.value} min`}
        />
        <TotalItem
          column="sabado"
          summaryType="sum"
          customizeText={(data) => `${data.value} min`}
        />
        <TotalItem
          column="domingo"
          summaryType="sum"
          customizeText={(data) => `${data.value} min`}
        />
        <TotalItem
          column="lunes"
          summaryType="sum"
          customizeText={(data) => `${data.value} min`}
        />
      </Summary>
      <ConfirmationDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onCancel={() => setOpenDialog(false)}
        onSuccess={deleteAvailabilityLocal}
      />
    </DataGrid>
  );
}

export { AvailabilityAdmin };
