import React from "react";
import { AuthContext } from "../Context/authContext";
import { ADMIN } from "../Utils/config";
import { MinutesWorkedMain } from "./minutesWorked";
import { MinutesWorkedWorkers } from "./minutesWorkedWorkers";

function MinutesWorkedSelection(){        
    const { getRol } = React.useContext(AuthContext);

  if (getRol() === ADMIN) {
    return <MinutesWorkedMain />;
  } 
  return <MinutesWorkedWorkers />
}


export { MinutesWorkedSelection };