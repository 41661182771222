import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUsers } from "../API/users.api";
import { getRolAsString } from "../Utils/utils";
import { AuthContext } from "./authContext";
const DataContext = React.createContext();

const DataProvider = ({ children }) => {
  const [activeUser, setActiveUser] = React.useState(null);
  const [activeRecording, setActiveRecording] = React.useState(null);
  const [isModalShown, setModaVisibility] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const { getToken, logout } = React.useContext(AuthContext)
  const navigate = useNavigate();

  const updateUsers = () => {
    getUsers(getToken()).then((response) => {
      if (response.status === 403){
        logout()
        navigate("/")
        return toast.error("You are not allowed to see this content.");        
      }
      if (response.status === 500)
        return toast.error("Couldn't retrieve users's information due to an internal server error.");        
      setUsers(response.body.map(user => {        
        return {
          ...user,
          rol: getRolAsString(user.RolUsuario, user.supervisor)
        }
      }));
    });
  };

  const openModal = (user) => {
      if(user !== -1){
        setActiveUser(user)
      }
      
      setModaVisibility(true)
  }

  const closeModal = () => {
      setActiveUser(null);
      setModaVisibility(false)
  }  

  return (
    <DataContext.Provider
      value={{
        activeUser,
        setActiveUser,
        activeRecording,
        setActiveRecording,
        isModalShown,
        closeModal,
        users,
        updateUsers,
        openModal
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
