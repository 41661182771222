import React, { useEffect } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Pager,
  Paging,
  GroupPanel,
  ColumnChooser,
  Export,
  TotalItem,
  GroupItem,
  Selection,
  Summary,
  Grouping,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver";
import { CheckBox } from "devextreme-react";
import { Divider } from "@mui/material";

function BalanceGeneralTable({ recordings }) {
  const [expandAll, setExpandAll] = React.useState(false);

  function onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Balance");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "DataGrid.xlsx"
        );
      });
    });
    e.cancel = true;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <CheckBox
        text="Expand All Groups"
        value={expandAll}
        onValueChanged={() => setExpandAll(!expandAll)}
        style={{marginBottom: "20px"}}
      />

      <DataGrid
        dataSource={recordings}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="nextColumn"
        onExporting={onExporting}
        // defaultColumns={columns}
        // rowRender={plantillaFila}
      >
        <Selection mode="multiple" />
        <GroupPanel visible={true} />
        <Grouping autoExpandAll={expandAll} />
        <ColumnChooser enabled={true} />
        {/* <Column dataField="id" caption="id" dataType="number" visible={false} /> */}
        <Column
          dataField="Publicacion"
          caption="Publication Date"
          dataType="date"
          allowFiltering
          allowSorting
          groupIndex={0}
        />
        <Column
          dataField="Nombre"
          caption="Name"
          dataType="string"
          allowSorting={true}
          allowFiltering={true}
          allowResizing
          width={240}
        />
        <Column
          dataField="Duracion"
          caption="Duration"
          dataType="number"
          allowSearch={true}
          allowHeaderFiltering={false}
          allowSorting
          cellRender={(rowInfo) => {
            return (
              <>
                {Math.trunc(rowInfo.data.Duracion)} min{" "}
                {(60 * (rowInfo.data.Duracion % 1)).toFixed(0)} s
              </>
            );
          }}
          allowResizing
        />
        <Column
          dataField="Cliente"
          caption="Client"
          dataType="string"
          allowSorting={true}
          allowFiltering={true}
          allowResizing
          visible={false}
        />
        <Column
          dataField="PagoCliente"
          caption="Client payment"
          dataType="number"
          format="currency"
          allowSearch={true}
          allowHeaderFiltering={false}
          allowSorting
          cellRender={(rowInfo) => `$${rowInfo.data.PagoCliente.toFixed(2)}`}
        />
        <Column
          dataField="Traductor"
          caption="Translator"
          dataType="string"
          allowSorting={true}
          allowFiltering={true}
          allowResizing
          visible={false}
        />
        <Column
          dataField="MultiplicadorTraductor"
          caption="Translator Multiplier"
          dataType="number"
          allowSorting={true}
          allowFiltering={true}
          allowResizing
          visible={false}
        />
        <Column
          dataField="PagoTraductor"
          caption="Translator payment"
          dataType="number"
          format="currency"
          allowSearch={true}
          allowHeaderFiltering={false}
          allowSorting
          cellRender={(rowInfo) => `$${rowInfo.data.PagoTraductor.toFixed(2)}`}
        />
        <Column
          dataField="Revisor"
          caption="Reviewer"
          dataType="string"
          allowSorting={true}
          allowFiltering={true}
          allowResizing
          visible={false}
        />
        <Column
          dataField="MultiplicadorRevisor"
          caption="Reviewer Multiplier"
          dataType="number"
          allowSorting={true}
          allowFiltering={true}
          allowResizing
          visible={false}
        />
        <Column
          dataField="PagoRevisor"
          caption="Reviewer payment"
          dataType="number"
          format="currency"
          allowSearch={true}
          allowHeaderFiltering={false}
          allowSorting
          cellRender={(rowInfo) => `$${rowInfo.data.PagoRevisor.toFixed(2)}`}
        />
        <Column
          dataField="Total"
          caption="Balance"
          dataType="number"
          format="currency"
          allowSearch={true}
          allowHeaderFiltering={false}
          allowSorting
          cellRender={(rowInfo) => `$${rowInfo.data.Total.toFixed(2)}`}
        />

        <FilterRow visible={true} />
        <FilterPanel visible={false} />

        <HeaderFilter visible={true} />

        <Paging defaultPageSize={50} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[100, 200, 500, 1000]}
          showInfo={true}
        />
        <Export enabled={true} allowExportSelectedData={true} />
        <Summary>
          <TotalItem column="Nombre" summaryType="count" />
          <GroupItem column="Nombre" summaryType="count" alignByColumn={true} />
          <TotalItem
            column="Duracion"
            summaryType="sum"
            valueFormat="number"
            customizeText={(data) =>
              `${Math.trunc(data.value)} min ${(60 * (data.value % 1)).toFixed(
                0
              )} s `
            }
          />
          <GroupItem
            column="Duracion"
            summaryType="sum"
            valueFormat="number"
            howInGroupFooter={false}
            alignByColumn={true}
            customizeText={(data) =>
              `${Math.trunc(data.value)} min ${(60 * (data.value % 1)).toFixed(
                0
              )} s `
            }
          />
          <TotalItem
            column="Total"
            summaryType="sum"
            valueFormat="currency"
            customizeText={(data) => `$${data.value.toLocaleString("en")}`}
          />
          <GroupItem
            column="Total"
            summaryType="sum"
            valueFormat="currency"
            howInGroupFooter={false}
            alignByColumn={true}
            customizeText={(data) => `$${data.value.toLocaleString("en")}`}
          />
          <TotalItem
            column="PagoCliente"
            summaryType="sum"
            valueFormat="currency"
            customizeText={(data) => `$${data.value.toLocaleString("en")}`}
          />
          <GroupItem
            column="PagoCliente"
            summaryType="sum"
            valueFormat="currency"
            howInGroupFooter={false}
            alignByColumn={true}
            customizeText={(data) => `$${data.value.toLocaleString("en")}`}
          />
          <TotalItem
            column="PagoTraductor"
            summaryType="sum"
            valueFormat="currency"
            customizeText={(data) => `$${data.value.toLocaleString("en")}`}
          />
          <GroupItem
            column="PagoTraductor"
            summaryType="sum"
            valueFormat="currency"
            howInGroupFooter={false}
            alignByColumn={true}
            customizeText={(data) => `$${data.value.toLocaleString("en")}`}
          />
          <TotalItem
            column="PagoRevisor"
            summaryType="sum"
            valueFormat="currency"
            customizeText={(data) => `$${data.value.toLocaleString("en")}`}
          />
          <GroupItem
            column="PagoRevisor"
            summaryType="sum"
            valueFormat="currency"
            howInGroupFooter={false}
            alignByColumn={true}
            customizeText={(data) => `$${data.value.toLocaleString("en")}`}
          />
        </Summary>
        {/* <Summary>
          <GroupItem
            column="Minutos"
            summaryType="sum"
            displayFormat="Total: {0}"
            alignByColumn={true}            
            customizeText={(data) => `${Math.trunc(data.value)} min ${(60 * (data.value % 1)).toFixed(0)} s `}
          />
          <GroupItem
            column="Pago"
            summaryType="sum"
            displayFormat="Total: {0}"
            alignByColumn={true}
            customizeText={(data) => "$" + data.value.toFixed(2)}
          />
        </Summary> */}
      </DataGrid>
    </div>
  );
}

export { BalanceGeneralTable };
