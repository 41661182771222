import { HOST } from "../Utils/config";
import { makeResponse } from "../Utils/fetchResponses";
import { fetchResponse, getHTTPStatusAsText } from "./fetchResponse";
const originalFetch = require("isomorphic-fetch");
const fetch = require("fetch-retry")(originalFetch, {
  retries: 5,
  retryDelay: 800,
});

async function getRecordings(date, token) {
  try {
    const response = await fetch(`${HOST}/api/v1/recordings?date=${date}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function getRecording(id, token) {
  try {
    const response = await fetch(`${HOST}/api/v1/recordings/${id}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function getRecordingsToTranslate(date, token, path) {
  try {
    const response = await fetch(`${HOST}/api/v1/recordings/${path}/${date}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function uploadRecordings(file, url, ContentType) {
  try {
    const response = await fetch(url, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": ContentType,
      },
    });
    if (response.ok) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

async function uploadWord(formData, token, rol) {
  try {
    const response = await fetch(`${HOST}/api/v1/recordings/${rol}/word`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    if (response.ok) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

async function getPresignedURL(fileData, token) {
  try {
    const response = await fetch(`${HOST}/api/v1/recordings/presigned`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(fileData),
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function createRecording(recording, token) {
  try {
    const response = await fetch(`${HOST}/api/v1/recordings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(recording),
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function updateRecording(recording, token) {
  try {
    const response = await fetch(`${HOST}/api/v1/recordings/${recording.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(recording),
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function deleteRecording(id, token) {
  try {
    const response = await fetch(`${HOST}/api/v1/recordings/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function addSubmision(recording, id, token) {
  try {
    const response = await fetch(
      `${HOST}/api/v1/recordings/assignments/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(recording),
      }
    );
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return fetchResponse(200, jsonResponse.body, getHTTPStatusAsText(200));
      }
      return fetchResponse(204, null, getHTTPStatusAsText(204));
    } else if(response.status === 406) {
      return fetchResponse(406, null, getHTTPStatusAsText(406));
    }
    return fetchResponse(
      response.status,
      null,
      getHTTPStatusAsText(response.status)
    );
  } catch (error) {
    return fetchResponse(500, null, error);
  }
}

export {
  uploadRecordings,
  createRecording,
  getRecordings,
  getRecording,
  updateRecording,
  deleteRecording,
  getRecordingsToTranslate,
  getPresignedURL,
  addSubmision,
  uploadWord,
};
