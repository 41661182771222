import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { Checkbox, FormControlLabel, MenuItem } from "@mui/material";
import { createPeriodos, updatePeriodo } from "../../API/periodos.api";
import { AuthContext } from "../../Context/authContext";
import { useNavigate } from "react-router-dom";
import DateBox from "devextreme-react/date-box";
import moment from "moment";

function PeriodosForm({ setOpen, updatePeriodos, periodoActivo }) {
  const [nombre, setNombre] = useState(periodoActivo.nombre || "");
  const [descripcion, setDescripcion] = useState(
    periodoActivo.descripcion || ""
  );
  const [fechaInicio, setFechaInicio] = useState(
    periodoActivo.fechaInicio || moment().startOf("month").format("YYYY-MM-DD")
  );
  const [fechaFin, setFechaFin] = useState(
    periodoActivo.fechaFin || moment().endOf("month").format("YYYY-MM-DD")
  );
  const [visible, setVisible] = React.useState(periodoActivo.visible || false);
  const [aprobado, setAprobado] = React.useState(
    periodoActivo.aprobado || false
  );
  const { getToken, logout } = React.useContext(AuthContext);
  const navigate = useNavigate();

  function isDataComplete() {
    return nombre !== "" && fechaInicio !== "" && fechaFin !== "";
  }

  function save() {
    if (!isDataComplete()) {
      return toast.error("Please fill the entire form");
    }
    const periodo = {
      nombre,
      descripcion,
      fechaInicio,
      fechaFin,
      visible,
      aprobado,
    };
    if (periodoActivo.id) {
      periodo.id = periodoActivo.id;
      updateIndicador(periodo);
    } else {
      createIndicador(periodo);
    }
  }

  const createIndicador = (periodo) => {
    createPeriodos(periodo, getToken()).then((response) => {
      if (response.status === 200) {
        toast.success("Payment period created successfully");
        setOpen(false);
        updatePeriodos();
      } else if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      } else {
        return toast.error(
          "There was an error creating the payment period. Please try again later."
        );
      }
    });
  };

  const updateIndicador = (periodo) => {
    updatePeriodo(periodo, getToken()).then((response) => {
      if (response.status === 200) {
        toast.success("Payment period modified successfully");
        setOpen(false);
        updatePeriodos();
      } else if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      } else {
        return toast.error(
          "There was an error modifying the payment period. Please try again later."
        );
      }
    });
  };

  return (
    <div style={{ maxHeight: "550px", overflow: "auto" }}>
      <Grid container spacing={3} sx={{ marginTop: "0px" }}>
        <Grid item xs={6}>
          <TextField
            id="nombre"
            label="Nombre"
            variant="outlined"
            value={nombre}
            required
            style={{ width: "100%" }}
            onChange={(e) => setNombre(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="descripcion"
            label="Descripción"
            variant="outlined"
            value={descripcion}
            multiline
            style={{ width: "100%" }}
            onChange={(e) => setDescripcion(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <DateBox
            label="Initial Date:"
            value={fechaInicio}
            onValueChanged={(e) => setFechaInicio(e.value)}
            type="date"
          />
        </Grid>
        <Grid item xs={6}>
          <DateBox
            label="Last Date: "
            value={fechaFin}
            onValueChanged={(e) => setFechaFin(e.value)}
            type="date"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            sx={{ marginLeft: "5px" }}
            control={
              <Checkbox
                checked={aprobado}
                value={aprobado}
                onChange={(e) => setAprobado(!aprobado)}
                color="default"
              />
            }
            label="Aproved?"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            sx={{ marginLeft: "5px" }}
            control={
              <Checkbox
                checked={visible}
                value={visible}
                onChange={(e) => setVisible(!visible)}
                color="default"
              />
            }
            label="Visible for employees?"
          />
        </Grid>
        <Grid item xs={12} lg={12} sm={12}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#69bd4b",
              color: "white",
              marginRight: "20px",
            }}
            onClick={() => save()}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export { PeriodosForm };
