import { Divider, IconButton, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import AssignmentSharpIcon from "@mui/icons-material/AssignmentSharp";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import Confirm from "../../General/confirm";
import moment from "moment";
import { deletePeriodos } from "../../API/periodos.api";
import { AuthContext } from "../../Context/authContext";

function Periodo({ periodo, setModalOpen, setPeriodoActivo, updatePeriodos }) {
  const [selected, setSelected] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const { getToken, logout } = React.useContext(AuthContext);
  const navigate = useNavigate();

  function removeIndicador() {
    deletePeriodos(periodo.id, getToken()).then((response) => {
      if (response.status === 200) {
        toast.success("Payment period deleted successfully");
        setPeriodoActivo({});
        setOpenConfirm(false);
        updatePeriodos();
      } else if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      } else {
        return toast.error(
          "Couldn't retrieve periods data. Please try again later."
        );
      }
    });
  }

  return (
    <>
      <section
        style={{
          padding: "10px 0",
          display: "flex",
          alignItems: "center",
          margin: "0px 3%",
        }}
      >
        <AssignmentSharpIcon
          fontSize="large"
          color={`${selected ? "primary" : "inherit"}`}
          onClick={() => navigate(`${periodo.id}`)}
          style={{ cursor: "pointer" }}
          onMouseOver={() => setSelected(true)}
          onMouseOut={() => setSelected(false)}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div>
              <Tooltip title="Editar">
                <IconButton
                  onClick={() => {
                    setModalOpen(true);
                    setPeriodoActivo(periodo);
                  }}
                >
                  <EditIcon color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar">
                <IconButton onClick={() => setOpenConfirm(true)}>
                  <DeleteForeverIcon color="error" />
                </IconButton>
              </Tooltip>
            </div>

            <b>{periodo.nombre}</b>
          </div>
          <b style={{ textAlign: "left", fontSize: "14px" }}>
            {moment(periodo.fechaInicio).format("MMMM Do YYYY")} -{" "}
            {moment(periodo.fechaFin).format("MMMM Do YYYY")}
          </b>
          <p style={{ textAlign: "left", margin: 2 }}>{periodo.descripcion}</p>
        </div>
      </section>

      <div style={{ width: "100%", justifyContent: "center" }}>
        <Divider sx={{ width: "96%", marginLeft: "2%" }} />
      </div>
      <Confirm
        open={openConfirm}
        title={"Delete payment period"}
        text={"¿Are you sure you want to delete this item?"}
        onConfirm={removeIndicador}
        handleCloseProp={() => setOpenConfirm(false)}
      />
    </>
  );
}

export { Periodo };
