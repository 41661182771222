import React, { useEffect } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { toast } from "react-toastify";
import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { RecordingsTitle } from "./recordingsTitle.jsx";
import { deleteRecording, getRecordings } from "../../API/recordings.api.js";
import { ColumnChooser, ColumnFixing } from "devextreme-react/tree-list";
import { Scrolling } from "devextreme-react/pivot-grid";
import { ConfirmationDialog } from "../../General/confirmationDialog.jsx";
import { RecordingConfigurations } from "./recordingConfigurations.jsx";
import { AuthContext } from "../../Context/authContext.jsx";
import moment from "moment";
import { DateBox } from "devextreme-react";

let recordingId = -1;

function RecordingsList() {
  const [recordingsForDataGrid, setRecordingsForDataGrid] = React.useState([]);
  const [date, setDate] = React.useState(moment().format("YYYY-MM-DD"));  
  const [recordings, setRecordings] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [filesToModify, setFilesToModify] = React.useState([]);
  const { getToken, logout } = React.useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    updateRecordings();
  }, []);

  useEffect(() => {
    updateRecordings();
  }, [filesToModify, date]);

  const updateRecordings = () => {
    getRecordings(date, getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve recordings due to an internal server error. Please try again later."
        );
      const data = response.body;
      setRecordings(data);
      setRecordingsForDataGrid(
        data.map((recording) => {
          return {
            cliente: recording.Cliente?.nombre,
            reviewer: recording.Reviewer?.nombreCompleto,
            translator: recording.Translator?.nombreCompleto,
            convict: recording.convict,
            day: recording.day,
            dueDate: recording.dueDate,
            duration: recording.duration,
            minutos: recording.minutos,
            segundos: recording.segundos,
            fechaCreacion: recording.fechaCreacion,
            id: recording.id,
            link: recording.link,
            maxDueDate: recording.maxDueDate,
            month: recording.month,
            name: recording.name,
            publicationDate: recording.publicationDate,
            reviewerFile: recording.reviewerFile,
            reviewerMultiplier: recording.reviewerMultiplier,
            state: recording.state,
            translatorFile: recording.translatorFile,
            translatorMultiplier: recording.translatorMultiplier,
            year: recording.year,
          };
        })
      );
    });
  };

  const redirectToUpload = () => {
    navigate("./upload");
  };

  const deleteRecordingLocal = async () => {
    const id = recordingId;
    recordingId = -1;
    deleteRecording(id, getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "There was an error deleting the recording. Please try again later."
        );
      toast.success("Recording deleted successfully.");
      updateRecordings();
      setOpenDialog(false);
    });
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Editar"
          style={{ marginRight: 10, cursor: "pointer", color: "#337ab7" }}
        >
          <Edit
            onClick={(e) => {              
              navigate(`${rowInfo.data.id}`);
            }}
          />
        </Tooltip>

        <Tooltip title="Delete" style={{ marginRight: 10, cursor: "pointer" }}>
          <Delete
            style={{ cursor: "pointer", color: "#f01e0f" }}
            onClick={() => {
              recordingId = rowInfo.data.id;
              setOpenDialog(true);
            }}
          />
        </Tooltip>
      </>
    );
  };

  const durationFormater = (rowInfo) => {
    return (
      <>
        {Math.trunc(rowInfo.data.minutos)} min{" "}
        {Math.trunc(rowInfo.data.segundos)} sec{" "}
        
      </>
    );
  };

  if (filesToModify.length === 0) {
    return (
      <div>
        <RecordingsTitle />
        <div style={{ display: "flex", alignItems: "center" }}>
          <b style={{ marginRight: "10px" }}>Publication date: </b>
          <DateBox
            value={date}
            onValueChanged={(e) => setDate(e.value)}            
            type="date"            
          />
        </div>
        <DataGrid
          dataSource={recordingsForDataGrid}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnResizingMode="nextColumn"
          // defaultColumns={columns}
          // rowRender={plantillaFila}
        >
          <ColumnChooser enabled={true} />
          <ColumnFixing enabled={true} />
          <Scrolling mode="virtual" />

          <Column
            dataField="id"
            caption="id"
            dataType="number"
            visible={false}
          />
          <Column
            dataField="minutos"
            caption="minutos"
            dataType="number"
            visible={false}
          />
          <Column
            dataField="segundos"
            caption="segundos"
            dataType="number"
            visible={false}
          />
          <Column
            dataField="name"
            caption="Name"
            dataType="string"
            allowSorting={true}            
            allowResizing
          />
          <Column
            dataField="link"
            caption="Link"
            dataType="string"
            allowSorting={true}
            visible={false}
          />
          <Column
            dataField="convict"
            caption="Convict No."
            dataType="string"
            allowSorting={true}
            visible={false}
          />
          <Column
            dataField="day"
            caption="Day"
            dataType="string"
            width={65}
            allowSorting={true}
            visible={false}
          />
          <Column
            dataField="month"
            caption="Month"
            dataType="string"
            width={75}
            allowSorting={true}
            visible={false}
          />
          <Column
            dataField="year"
            caption="Year"
            dataType="string"
            width={65}
            allowSorting={true}
            visible={false}
          />
          <Column
            dataField="translatorMultiplier"
            caption="Translator Mult."
            dataType="number"
            width={115}
            format={{
              type: "fixedPoint",
              precision: 2,
            }}
            allowFiltering={true}
            allowSorting={true}
            visible={false}
          />
          <Column
            dataField="reviewerMultiplier"
            caption="Reviewer Mult."
            width={115}
            format={{
              type: "fixedPoint",
              precision: 2,
            }}
            dataType="number"
            allowFiltering={true}
            allowSorting={true}
            visible={false}
          />
          <Column
            dataField="duration"
            caption="Duration"
            width={115}
            cellRender={durationFormater}
            dataType="number"
            allowFiltering={true}
            allowSorting={true}
          />
          <Column
            dataField="publicationDate"
            caption="Publication date"
            dataType="date"
            format="longDate"
            allowFiltering={true}
            allowSorting={true}
            visible={false}
          />
          <Column
            dataField="dueDate"
            caption="Due Date"
            dataType="datetime"
            format="longDateLongTime"
            allowFiltering={true}
            allowSorting={true}
          />
          <Column
            dataField="maxDueDate"
            caption="Max Due Date"
            dataType="datetime"
            format="longDateLongTime"
            allowFiltering={true}
            allowSorting={true}
            visible={true}
          />

          <Column
            dataField="cliente"
            caption="Client"
            dataType="string"
            allowFiltering={true}
            allowSorting={true}
            width={100}
          />
          <Column
            dataField="translator"
            caption="Translator"
            dataType="string"
            width={130}
            allowFiltering={true}
            allowSorting={true}
          />
          <Column
            dataField="reviewer"
            caption="Reviewer"
            dataType="string"
            width={130}
            allowFiltering={true}
            allowSorting={true}
          />
          <Column
            caption="Options"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
            width={110}
          />
          <FilterRow visible={true} />
          <FilterPanel visible={false} />
          {/* <FilterBuilderPopup position={filterBuilderPopupPosition} /> */}
          <HeaderFilter visible={true} />
          {/* <Scrolling mode="infinite" /> */}

          {/* <GroupPanel visible={true} /> */}
          <SearchPanel visible={true} highlightCaseSensitive={true} />
          {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
          {/* <Paging defaultPageSize={10} /> */}
          <Paging defaultPageSize={50} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[50, 100, 150, 200]}
            showInfo={true}
          />
        </DataGrid>
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            right: 10,
            bottom: 10,
            backgroundColor: "#aebd36",
          }}
          onClick={redirectToUpload}
        >
          <AddIcon />
        </Fab>
        <ConfirmationDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          onCancel={() => setOpenDialog(false)}
          onSuccess={deleteRecordingLocal}
        />
      </div>
    );
  } else {
    return (
      <>
        <RecordingsTitle />
        <RecordingConfigurations
          files={filesToModify}
          mode="update"
          setFilesToModify={setFilesToModify}
        />
      </>
    );
  }
}

export { RecordingsList };
