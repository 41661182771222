import React from "react";
import { LoginInput } from "../components/loginInput";
import { NavBar } from "./navBar";

export default function Login() {
  return (
    <>
      <NavBar />
      <LoginInput />
    </>
  );
}
