import { HOST } from "../Utils/config";
import { makeResponse } from "../Utils/fetchResponses";
const originalFetch = require("isomorphic-fetch");
const fetch = require("fetch-retry")(originalFetch, {
  retries: 5,
  retryDelay: 800,
});

async function getUsers(token) {
  try {
    const response = await fetch(`${HOST}/api/v1/usuarios`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function getTranslators(token) {
  try {
    const response = await fetch(`${HOST}/api/v1/usuarios/translators`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function getReviewers(token) {
  try {
    const response = await fetch(`${HOST}/api/v1/usuarios/reviewers`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function createUser(user, token) {
  try {
    const response = await fetch(`${HOST}/api/v1/usuarios`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(user),
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function updateUser(user, id, token) {
  try {
    const response = await fetch(`${HOST}/api/v1/usuarios/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(user),
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}


async function updatePassword(userKeys, token) {
  try {
    const response = await fetch(`${HOST}/api/v1/usuarios/passwordUpdate`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userKeys),
    });
    if (response.ok) {
      return makeResponse(200, "User updated successfully");      
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function deleteUser(id, token) {
  try {
    const response = await fetch(`${HOST}/api/v1/usuarios/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

export {
  getUsers,
  createUser,
  deleteUser,
  updateUser,
  updatePassword,
  getReviewers,
  getTranslators,
};
