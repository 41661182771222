import React from "react";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";

function RecordingsTitle() {
  return (
    <div
      style={{
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 10px",
        borderRadius: "15px",
        marginBottom: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <KeyboardVoiceIcon fontSize="large" sx={{ margin: "0 2px" }} />
      <h1 style={{ margin: 10 }}>Recordings</h1>
    </div>
  );
}

export { RecordingsTitle }