import React, { useEffect } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Pager,
  Paging,
  GroupPanel,
  SearchPanel,
} from "devextreme-react/data-grid";
import DateBox from "devextreme-react/date-box";
import { toast } from "react-toastify";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import { useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import { AuthContext } from "../Context/authContext.jsx";
import {
  addSubmision,
  getRecordingsToTranslate,
  uploadWord,
} from "../API/recordings.api.js";
import { Loading } from "../General/loading.jsx";
import {
  ADMIN,
  REVIEWER,
  S3_BUCKET_WORD_REVISOR,
  S3_BUCKET_WORD_TRADUCTOR,
} from "../Utils/config.js";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { getPath } from "../Utils/utils.js";
import moment from "moment";
import { AvailabilityReminder } from "../Availability/users/availabilityReminder.jsx";
import { TranslationComments } from "../Translator/translatorCommets.jsx";
import { TranslatorRecordingStats } from "../Translator/translatorRecordingStats.jsx";

let isReviewer = false;

function SupervisorView() {
  const [date, setDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [recordingsForDataGrid, setRecordingsForDataGrid] = React.useState([]);
  const { getToken, logout, getRol, getSupervisor } = React.useContext(AuthContext);
  const [openModal, setOpenModal] = React.useState(false);
  const [idActual, setIdActual] = React.useState(-1);  
  const [recordingComments, setRecordingComments] = React.useState("");
  const [updateStats, setUpdateStats] = React.useState(false);
  let [loading, setLoading] = React.useState(false);  
  const navigate = useNavigate();

  useEffect(() => {
    if (!getSupervisor()) {
      navigate("/");
      logout();
    }
    updateRecordings();
  }, []);

  useEffect(() => {
    updateRecordings();
  }, [date]);

  const updateRecordings = () => {
    getRecordingsToTranslate(date, getToken(), getPath(getRol(), getSupervisor())).then(
      (response) => {
        setUpdateStats(!updateStats);
        if (response.status === 403) {
          logout();
          navigate("/");
          return toast.error("You are not allowed to see this content.");
        }
        if (response.status === 500)
          return toast.error(
            "Couldn't retrieve recordings due to an internal server error. Please try again later."
          );
        const data = response.body;
        setRecordingsForDataGrid(
          data.map((recording) => {
            return {
              dueDate: recording.dueDate,
              id: recording.id,
              maxDueDate: recording.maxDueDate,
              name: recording.name,
              publicationDate: recording.publicationDate,
              translatorFile: recording.translatorFile,
              reviewerFile: recording.reviewerFile,
              link: recording.link,
              duration: recording.duration,
              minutos: recording.minutos,
              segundos: recording.segundos,
              comments: recording.comments,
              state: getState(recording),
              cliente: recording.Cliente?.nombre,
              traductor: recording.Translator?.nombreCompleto,
              revisor: recording.Reviewer?.nombreCompleto,
            };
          })
        );
      }
    );
  };

  const getState = (recording) => {
    if (!recording.translatorFile) {
      return "Pending - Translator";
    }
    if (!recording.reviewerFile) {
      return "Pending - Reviewer";
    }
    return "Reviewed";
  };

  const download = async (url, name, download = true) => {
    return new Promise((resolve, reject) => {
      if (!url) {
        toast.error("Resource URL not provided! You need to provide one");
      }
      setLoading(true);
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          setLoading(false);
          if (download) {
            const blobURL = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = blobURL;
            a.style = "display: none";

            if (name && name.length) a.download = name;
            document.body.appendChild(a);
            a.click();
          }
          resolve(blob);
        })
        .catch(() => {
          toast.error("Error while downloading the file");
          reject();
        });
    });
  };

  const downloadAllRecordings = async () => {
    const zip = new JSZip();
    const recordingsZip = zip.folder("recordings");
    for (let index = 0; index < recordingsForDataGrid.length; index++) {
      const recording = recordingsForDataGrid[index];
      const fileAsBlob = await download(recording.link, recording.name, false);
      recordingsZip.file(recording.name, fileAsBlob);
    }
    recordingsZip.generateAsync({ type: "blob" }).then(function (blob) {
      saveAs(blob, "recordings.zip");
    });
  };

  const downloadAllTranslatorDocs = async () => {
    const zip = new JSZip();
    const recordingsZip = zip.folder("translations");
    for (let index = 0; index < recordingsForDataGrid.length; index++) {
      const recording = recordingsForDataGrid[index];
      if (!recording.translatorFile) continue;
      const fileAsBlob = await download(
        recording.translatorFile,
        recording.name.split(".")[0] + "-Trans-V.docx",
        false
      );
      recordingsZip.file(
        recording.name.split(".")[0] + "-Trans-V.docx",
        fileAsBlob
      );
    }
    recordingsZip.generateAsync({ type: "blob" }).then(function (blob) {
      saveAs(blob, "translations.zip");
    });
  };

  const downloadAllReviewerDocs = async () => {
    const zip = new JSZip();
    const recordingsZip = zip.folder("reviewings");
    for (let index = 0; index < recordingsForDataGrid.length; index++) {
      const recording = recordingsForDataGrid[index];
      if (!recording.reviewerFile) continue;
      const fileAsBlob = await download(
        recording.reviewerFile,
        recording.name.split(".")[0] + "-Trans-V.docx",
        false
      );
      recordingsZip.file(
        recording.name.split(".")[0] + "-Trans-V.docx",
        fileAsBlob
      );
    }
    recordingsZip.generateAsync({ type: "blob" }).then(function (blob) {
      saveAs(blob, "reviewings.zip");
    });
  };

  const downloadWordFile = (e, filepath) => {
    const fileName = filepath.split("/")[3].replaceAll("+", "-");
    download(filepath, fileName);
    e.preventDefault();
  };

  const reviewerFilesColumn = (rowInfo) => {
    return (
      <>
        <Tooltip
          title={rowInfo.data.reviewerFile ? "Download" : "Pending"}
          style={{ marginRight: 10, cursor: "pointer", color: "#337ab7" }}
        >
          {rowInfo.data.reviewerFile ? (
            <CloudDownloadIcon
              style={{
                color: "orange",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={(e) => downloadWordFile(e, rowInfo.data.reviewerFile)}
            />
          ) : (
            <PendingActionsIcon style={{ cursor: "default" }} />
          )}
        </Tooltip>
      </>
    );
  };

  const translatorFilesColumn = (rowInfo) => {
    return (
      <>
        <Tooltip
          title={rowInfo.data.translatorFile ? "Download" : "Pending"}
          style={{ marginRight: 10, cursor: "pointer", color: "#337ab7" }}
        >
          {rowInfo.data.translatorFile ? (
            <CloudDownloadIcon
              style={{
                color: "orange",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={(e) => downloadWordFile(e, rowInfo.data.translatorFile)}
            />
          ) : (
            <PendingActionsIcon style={{ cursor: "default" }} />
          )}
        </Tooltip>
      </>
    );
  };

  const commentsColumn = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="View comments..."
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          {!rowInfo.data.comments ? (
            <AddCommentIcon
              style={{ cursor: "pointer", color: "#aebd36" }}
              onClick={() => handleCommentsClick(rowInfo)}
            />
          ) : (
            <MarkUnreadChatAltIcon
              style={{ cursor: "pointer", color: "#1a5c9e" }}
              onClick={() => handleCommentsClick(rowInfo)}
            />
          )}
        </Tooltip>
      </>
    );
  };

  function handleCommentsClick(rowInfo) {
    setIdActual(rowInfo.data.id);
    setRecordingComments(rowInfo.data.comments);
    setOpenModal(true);
  }

  

  return (
    <div>
        <div
      style={{
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 10px",
        borderRadius: "15px",
        marginBottom: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      
      <h1 style={{ margin: 10 }}>Supervisor View</h1>
    </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <b style={{ marginRight: "10px" }}>Date: </b>
          <DateBox
            value={date}
            onValueChanged={(e) => setDate(e.value)}
            type="date"
          />
        </div>
        <div>
          <b>Download: </b>
          <Button
            onClick={downloadAllRecordings}
            variant="contained"
            style={{ marginBottom: "10px", marginLeft: "10px", fontSize: 12 }}
          >
            Recordings
          </Button>
          <Button
              onClick={downloadAllTranslatorDocs}
              variant="contained"
              style={{ marginBottom: "10px", marginLeft: "10px", fontSize: 12 }}
              color="warning"
            >
              Translator Documents
            </Button>
            <Button
              onClick={downloadAllReviewerDocs}
              variant="contained"
              style={{ marginBottom: "10px", marginLeft: "10px", fontSize: 12 }}
              color="success"
            >
              Reviewer Documents
            </Button>          
        </div>
      </div>
      {/* <RecordingsTitle /> */}
      <TranslatorRecordingStats recordings={recordingsForDataGrid} />

      <DataGrid
        dataSource={recordingsForDataGrid}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="nextColumn"
        // defaultColumns={columns}
        // rowRender={plantillaFila}
      >
        <GroupPanel visible={getRol() === ADMIN} />
        <Column dataField="id" caption="id" dataType="number" visible={false} />
        <Column
          dataField="minutos"
          caption="minutos"
          dataType="number"
          visible={false}
        />
        <Column
          dataField="segundos"
          caption="segundos"
          dataType="number"
          visible={false}
        />
        <Column
          dataField="translatorFile"
          caption="Translator File"
          dataType="string"
          visible={false}
        />
        <Column
          dataField="comments"
          caption="comments"
          dataType="string"
          visible={false}
        />
        <Column
          dataField="name"
          caption="Name"
          dataType="string"
          allowSorting={true}
          minWidth={220}
          allowResizing
        />
        <Column
          dataField="duration"
          caption="Duration"
          dataType="number"
          allowSorting={true}
          width={115}
          cellRender={(rowInfo) => {
            return (
              <>
                {Math.trunc(rowInfo.data.minutos)} min{" "}
                {Math.trunc(rowInfo.data.segundos)} sec{" "}
              </>
            );
          }}
          allowResizing
        />
        <Column
          dataField="link"
          caption="Link"
          dataType="string"
          allowSorting={true}
          visible={false}
        />
        <Column
          dataField="dueDate"
          caption="Due Date"
          dataType="datetime"
          format="longDateLongTime"
          allowFiltering={true}
          allowSorting={true}
        />
        <Column
          dataField="maxDueDate"
          caption="Max. Due date"
          dataType="date"
          format="longDateLongTime"
          allowFiltering={true}
          allowSorting={true}
          visible={getSupervisor()}
        />
        <Column
          dataField="state"
          caption="State"
          allowFiltering={true}
          allowSorting={true}
          visible={getSupervisor()}
        />
        <Column
          dataField="traductor"
          caption="Translator"
          allowFiltering={true}
          allowSorting={true}
          visible
        />
        <Column
          dataField="revisor"
          caption="Reviewer"
          allowFiltering={true}
          allowSorting={true}
          visible
        />
        <Column
          caption="Translator Files"
          allowFiltering={false}
          allowSorting={false}
          cellRender={translatorFilesColumn}
          width={120}
          visible={getSupervisor()}
        />
        <Column
          caption="Reviewer Files"
          allowFiltering={false}
          allowSorting={false}
          cellRender={reviewerFilesColumn}
          width={120}
          visible={getSupervisor()}
        />
        <Column
          caption="Comments"
          allowFiltering={false}
          allowSorting={false}
          cellRender={commentsColumn}
          width={120}
          visible={getSupervisor()}
        />

        <FilterRow visible={true} />
        <FilterPanel visible={false} />

        <HeaderFilter visible={true} />

        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Paging defaultPageSize={50} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[50, 100, 150, 200]}
          showInfo={true}
        />
      </DataGrid>
      <TranslationComments
        open={openModal}
        setOpen={setOpenModal}
        id={idActual}
        recordingCommets={recordingComments}
        updateRecordings={updateRecordings}
      />
      <Loading loading={loading} phrase={"Loading..."} />
      <AvailabilityReminder />
    </div>
  );
}

export { SupervisorView };
