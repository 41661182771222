import React from "react";

function DashboardDataDisplay({value, description, backgroundColor, fontColor, visible = true}){
    return (
    <div style={{
        width: "300px",
        height: "240px",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 10px",
        borderTopRightRadius: "30px",
        borderBottomLeftRadius: "30px",        
        display: visible? "flex" : "none",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: 120,        
    }}>
        <div></div>
        <b>{value}</b>
        <div style={{
            width: "300px",
            height: "60px",
            backgroundColor,
            borderTopRightRadius: "30px",
            borderBottomLeftRadius: "30px",            
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: fontColor,
            border: "1px solid gray",
            fontSize: 20
        }}>
            <p><b>{description}</b></p>            
        </div>
    </div>
    )
}


export {DashboardDataDisplay}