import Modal from "../../Custom/modal";
import React, { useEffect } from "react";
import {
  getDayAsString,
  getNextWeekDate,
  isValidToModify,
} from "./availabilityUsers";
import { getDisponibilidad } from "../../API/availability.api";
import { toast } from "react-toastify";
import moment from "moment";
import { AuthContext } from "../../Context/authContext";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ADMIN } from "../../Utils/config";
import { getConfig } from "../../API/config.api";
const INTERVAL_MINUTES = 60;
const CONFIG_AVAILABILITY = 1;

function AvailabilityReminder() {
  const [open, setOpen] = React.useState(false);
  const { getNombre, getRol } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const [initialDay, setInitialDay] = React.useState(-1);
  const [initialHour, setInitialHour] = React.useState(
    new Date(1997, 11, 11, 12, 0)
  );
  const [lastDay, setlastDay] = React.useState(-1);
  const [lastHour, setlastHour] = React.useState(new Date(1997, 11, 11, 13, 0));

  useEffect(() => {
    getConfig(CONFIG_AVAILABILITY).then((response) => {
      if (response.status === 200) {
        const availabilityConfig = JSON.parse(response.body.valor);        
        if (availabilityConfig) {
          setInitialDay(availabilityConfig.initialDay);
          setInitialHour(
            new Date(
              1997,
              11,
              11,
              availabilityConfig.initialHour,
              availabilityConfig.initialMinutes
            )
          );
          setlastDay(availabilityConfig.lastDay);
          setlastHour(
            new Date(
              1997,
              11,
              11,
              availabilityConfig.lastHour,
              availabilityConfig.lastMinutes
            )
          );
        }
      }
    });
  }, []);

  function isValidToModify() {
    if(initialDay === -1) return false;
    const todaysDate = new Date();
    const initialDate = new Date();
    let cont = 0;
    if (initialDay < initialDate.getDay()) {
      while (initialDay < initialDate.getDay()) {
        initialDate.setDate(initialDate.getDate() - 1);
        if (cont++ > 500) break;
      }
    }
    cont = 0;
    if (initialDay > initialDate.getDay()) {
      while (initialDay > initialDate.getDay()) {
        initialDate.setDate(initialDate.getDate() + 1);
        if (cont++ > 500) break;
      }
    }
    initialDate.setHours(initialHour.getHours(), initialHour.getMinutes(), 0);
    const lastDate = new Date(initialDate.getTime());    
    while (lastDate.getDay() < lastDay) {
      lastDate.setDate(lastDate.getDate() + 1);
    }
    lastDate.setHours(lastHour.getHours(), lastDate.getMinutes(), 0);    
    if (todaysDate > initialDate && todaysDate < lastDate) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (initialDay !== -1 && lastDay !== -1) {
      const lastReminderDate = sessionStorage.getItem("reminder");
      if (lastReminderDate) {
        if (diffInMinutes(new Date(lastReminderDate)) < INTERVAL_MINUTES) {
          return;
        }
      }

      if (isValidToModify() && getRol() !== ADMIN) {
        const date = moment(getNextWeekDate()).format("YYYY-MM-DD");
        getDisponibilidad(date).then((response) => {
          if (response.status === 200) {
            if (response.body.id) {
              setOpen(false);
            } else {
              setOpen(true);
            }
          } else {
            sessionStorage.setItem("reminder", new Date().toISOString());
            setOpen(true);
          }
        });
      }
    }
  }, [initialDay, initialHour, lastDay, lastHour]);

  function diffInMinutes(lastDate) {
    var today = new Date();
    var diffMs = today - lastDate;
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffMins;
  }

  function handleCloseModal() {
    setOpen(false);
  }

  function getClosingDate() {
    return `Please remember to fill out the availability form. It will be available until ${getDayAsString(
      lastDay
    )} ${lastHour.getHours()}:${
      (lastHour.getMinutes() < 10 ? "0" : "") + lastHour.getMinutes()
    }`;
  }

  return (
    <Modal open={open} handleClose={handleCloseModal} title=" ">
      <div>
        <h1>{`Hi, ${getNombre().split(" ")[0]}!`}</h1>

        <p style={{ fontSize: "1.5rem" }}>{getClosingDate()}</p>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            sx={{ width: "60%" }}
            onClick={() => navigate("availability")}
          >
            Do it Now!
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export { AvailabilityReminder };
