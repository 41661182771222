import { HOST } from "../Utils/config";
import { makeResponse } from "../Utils/fetchResponses";
import { getToken } from "../Utils/security";
const originalFetch = require("isomorphic-fetch");
const fetch = require("fetch-retry")(originalFetch, {
  retries: 5,
  retryDelay: 800,
});

async function getConfig(id) {
  try {
    const response = await fetch(`${HOST}/api/v1/configs/${id}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}
async function putConfig(disponibilidad, id) {
  try {
    const response = await fetch(`${HOST}/api/v1/configs/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(disponibilidad),
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

export { getConfig, putConfig };
