import React, { useEffect } from "react";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import { getClients } from "../../API/clients.api";
import { AuthContext } from "../../Context/authContext";
import { ClienteList } from "./clientsList";
import { ClientesModal } from "./clientsModal";

function ClientesMain() {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [clientes, setClientes] = React.useState([]);  
  const [clienteActivo, setClienteActivo] = React.useState({});  
  const { getToken } = React.useContext(AuthContext)

  useEffect(() => {
    updateClientes();
  }, []);

  function updateClientes() {
    getClients(getToken()).then((response) => {        
      if (response.status === 200) {
        setClientes(response.body);
      } else {
        toast.error(response.message);
      }
      setClienteActivo({});
    });
  }

  function closeModal() {
    setModalOpen(false);
  }

  function openModal() {
    setClienteActivo({});
    setModalOpen(true);
  }

  return (
    <div>
      <ClienteList
        clientes={clientes}
        setModalOpen={setModalOpen}
        setClienteActivo={setClienteActivo}
        updateClientes={updateClientes}
      />
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          right: 10,
          bottom: 10,
          backgroundColor: "#aebd36",
        }}
        onClick={() => openModal()}
      >
        <AddIcon />
      </Fab>

      <ClientesModal
        open={isModalOpen}
        setOpen={setModalOpen}
        updateClientes={updateClientes}
        clienteActivo={clienteActivo}
      />
    </div>
  );
}

export { ClientesMain };
