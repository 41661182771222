import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../Hooks/useAuth";

function ProtectedRoute({ children }) {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated()) {
      toast.error("Sorry, you need to sign in to continue", {toastId: 'noauth'})      
    return <Navigate to="/" />;
  }
  return children;
}

export { ProtectedRoute };
