import React, { useEffect } from "react";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import { PeriodoList } from "./periodosList";
import { PeriodosModal } from "./periodoModal";
import { getPeriodos } from "../../API/periodos.api";
import { AuthContext } from "../../Context/authContext";
import { useNavigate } from "react-router-dom";

function PeriodosMain() {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [periodos, setPeriodos] = React.useState([]);
  const [periodoActivo, setPeriodoActivo] = React.useState({});
  const { getToken, logout } = React.useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    updatePeriodos();
  }, [])

  function updatePeriodos() {
    getPeriodos(getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve periods data. Please try again later."
        );
      if (response.status === 200) {        
        setPeriodos(response.body);
      } else {
        toast.error("There was an error, please try again later.");
      }
      setPeriodoActivo({});
    });
  }

  function closeModal() {
    setModalOpen(false);
  }

  function openModal() {
    setPeriodoActivo({});
    setModalOpen(true);
  }

  return (
    <div>
      <PeriodoList
        periodos={periodos}
        setModalOpen={setModalOpen}
        setPeriodoActivo={setPeriodoActivo}
        updatePeriodos={updatePeriodos}
      />
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          right: 10,
          bottom: 10,
          backgroundColor: "#aebd36",
        }}
        onClick={() => openModal()}
      >
        <AddIcon />
      </Fab>
      <PeriodosModal
        open={isModalOpen}
        setOpen={setModalOpen}
        updatePeriodos={updatePeriodos}
        periodoActivo={periodoActivo}
      />
    </div>
  );
}

export { PeriodosMain };
