import {
    Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

function ConfirmationDialog({
  openDialog,
  setOpenDialog,
  onSuccess,
  onCancel,
}) {
  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Are you sure you want to delete this item?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Once you delete it, it will be erased from the database.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onCancel}>          
          Cancel
        </Button>
        <Button color="success" onClick={onSuccess}>          
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { ConfirmationDialog };
