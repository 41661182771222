import React from "react";
import { LogoComponent } from "../../Layout/containers/logoComponent";

function NavBar() {  
  return (
    <header style={loginHeader}>
      <LogoComponent />
    </header>
  );
}
export { NavBar };

const loginHeader = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: "#000030",
  color: "white",
  fontSize: 18,
  height: "60px",
  alignItems: "center",
  padding: "10px 10%",
  borderBottom: "1px solid black",
};
