import { HOST } from "../Utils/config";
import { makeResponse } from "../Utils/fetchResponses";
const originalFetch = require("isomorphic-fetch");
const fetch = require("fetch-retry")(originalFetch, {
  retries: 5,
  retryDelay: 800,
});

async function getMinutesByTranslator(options, token) {
  try {
    const response = await fetch(`${HOST}/api/v1/reportes/translatorMinutes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(options),
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function getMinutesByReviewer(options, token) {
  try {
    const response = await fetch(`${HOST}/api/v1/reportes/reviewerMinutes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(options),
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function getMinutesByTranslatorSummary(options, token) {
  try {
    const response = await fetch(
      `${HOST}/api/v1/reportes/translatorMinutesSummary`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(options),
      }
    );
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function getMinutesByReviewerSummary(options, token) {
  try {
    const response = await fetch(
      `${HOST}/api/v1/reportes/reviewerMinutesSummary`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(options),
      }
    );
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function getBalanceGeneral(initialDate, lastDate, token) {
  try {
    const response = await fetch(
      `${HOST}/api/v1/reportes/balanceTotal?fechaInicio=${initialDate}&fechaFin=${lastDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      }
    );
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

async function getBalanceGeneralResumido(initialDate, lastDate, token) {
  try {
    const response = await fetch(
      `${HOST}/api/v1/reportes/resumenbalanceTotal?fechaInicio=${initialDate}&fechaFin=${lastDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      }
    );
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.body) {
        return makeResponse(200, jsonResponse.body);
      }
      return makeResponse(500, null);
    }
    return makeResponse(response.status, null);
  } catch (error) {
    return makeResponse(500, null);
  }
}

export {
  getMinutesByTranslator,
  getMinutesByReviewer,
  getMinutesByTranslatorSummary,
  getMinutesByReviewerSummary,
  getBalanceGeneral,
  getBalanceGeneralResumido
};
