import { Button, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { addSubmision } from "../API/recordings.api";
import { AuthContext } from "../Context/authContext";

function TranslationComments({
  open,
  setOpen,
  id,
  recordingCommets,
  updateRecordings,
  maxDueDate,
}) {
  const [originalComment, setOriginalComment] = React.useState("");
  const [comments, setComments] = React.useState("");
  const { getToken } = React.useContext(AuthContext);

  useEffect(() => {
    setComments(recordingCommets === null ? "" : recordingCommets);
    setOriginalComment(recordingCommets === null ? "" : recordingCommets);
  }, [id, recordingCommets]);

  

  function handleClose() {
    setOpen(false);
  }

  function save() {
    if (moment().isAfter(moment(maxDueDate))) {    
      setComments(originalComment);  
      return toast.warning(
        "Sorry, the max due date to add comments was exceeded. Please contact the administrator."
      );
    }
    addSubmision({ comments }, id, getToken()).then((response) => {
      if (response.status === 200) {
        toast.success("Commets added successfully");
        handleClose();
        updateRecordings();
      }  else if (response.status === 406) {
        toast.error("The max due date was exceeded. Please contact the administrator and check your local timezone.");          
        setComments(originalComment);  
        handleClose();
      } else {
        toast.error(response.message);
      }
    });
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 10px",
            borderRadius: "15px",
            padding: "30px 50px",
            backgroundColor: "white",
            maxWidth: 900,
          }}
        >
          <h2 style={{ textAlign: "left" }}>Add Comments</h2>
          <TextField
            id="commets"
            label="Commets"
            variant="outlined"
            fullWidth
            multiline
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              width: "350px",
              justifyContent: "space-around",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#aebd36",
                width: 150,
                display: "block",
              }}
              onClick={save}
            >
              Add comment
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "15px",
  boxShadow: 24,
};

export { TranslationComments };
