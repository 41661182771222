import React, { useEffect } from "react";
import moment from "moment";
import {  
  getBalanceGeneralResumido,
} from "../../API/reportes.api";
import { AuthContext } from "../../Context/authContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function MoneyResume() {
  const [minutes, setMinutes] = React.useState(0);
  const [payment, setPayment] = React.useState(0);
  const [profit, setProfit] = React.useState(0);
  const { getToken, logout, getRol } = React.useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    updateRecordings();
  }, []);

  const updateRecordings = () => {
    const date = new Date();
    const firstDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      1
    ).toISOString();
    const lastDay = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).toISOString();
    getBalanceGeneralResumido(
      moment(firstDay).format("YYYY-MM-DD"),
      moment(lastDay).format("YYYY-MM-DD"),
      getToken()
    ).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve periods' information due to an internal server error."
        );      
      const balance = response.body;
      if (balance.length > 0) {
        setMinutes(
          `${Math.trunc(balance[0].Duracion)} min ${(
            60 *
            (balance[0].Duracion % 1)
          ).toFixed(0)} s`
        );
        setPayment(balance[0].PagoCliente.toLocaleString("en"));
        setProfit(balance[0].Total.toLocaleString("en"));
      }
    });
  };

  return (
    <div style={{ display: "flex", marginLeft: "10px" }}>
      <section style={{ margin: "0px 10px", fontSize: 14 }}>
        <b>Client's Minutes:</b> {minutes}
      </section>
      <section style={{ margin: "0px 10px", fontSize: 14 }}>
        <b>Client's Payment:</b> ${payment}
      </section>
      <section style={{ margin: "0px 10px", fontSize: 14 }}><b>Profit:</b> ${profit}</section>
    </div>
  );
}

export { MoneyResume };
