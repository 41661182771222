import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { RecordingsTitle } from "./recordingsTitle";
import moment from "moment";
import { AuthContext } from "../../Context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getClients } from "../../API/clients.api";
import { getReviewers, getTranslators } from "../../API/users.api";
import { getRecording, updateRecording } from "../../API/recordings.api";
import { DateBox } from "devextreme-react";

function ReacordingEdition() {
  const [id, setId] = React.useState(-1);
  const [translatorMultiplier, setTranslatorMultiplier] = React.useState(1);
  const [reviewerMultiplier, setReviewerMultiplier] = React.useState(1);
  const [client, setClient] = React.useState("");
  const [translator, setTranslator] = React.useState("");
  const [reviewer, setReviewer] = React.useState("");
  const [duration, setDuration] = React.useState(0);
  const [minutos, setMinutos] = React.useState(0);
  const [segundos, setSegundos] = React.useState(0);
  const [convict, setConvict] = React.useState("");
  const [name, setName] = React.useState("");
  const [recording, setRecording] = React.useState(null);
  const [deleteTranslatorFile, setDeleteTranslatorFile] = React.useState(false);
  const [deleteReviewerFile, setDeleteReviewerFile] = React.useState(false);
  const [enIngles, setEnIngles] = React.useState(false);
  const [dueDate, setDueDate] = React.useState(
    moment().add(1, "d").format("YYYY-MM-DDT15:00:00")
  );
  const [maxDueDate, setMaxDueDate] = React.useState(
    moment().add(1, "d").format("YYYY-MM-DDT18:00:00")
  );
  const [publicationDate, setpublicationDate] = React.useState(
    moment().add(1, "d").format("YYYY-MM-DD")
  );
  const [clients, setClients] = React.useState([]);
  const [translators, setTranslators] = React.useState([]);
  const [reviewers, setReviewers] = React.useState([]);
  const { getToken, logout } = React.useContext(AuthContext);
  const navigate = useNavigate();
  let params = useParams();

  React.useEffect(() => {
    updateClientes();
    updateReviewers();
    updateTranslators();
    if (params.recordingId) {
      updateRecordings(params.recordingId);
    }
  }, []);

  async function updateRecordings(recordingId) {
    getRecording(recordingId, getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve client's information due to an internal server error."
        );
      modifyActualRecordingValues(response.body);
    });
  }

  function modifyActualRecordingValues(recording) {    
    setId(recording.id);
    setTranslatorMultiplier(recording.translatorMultiplier);
    setReviewerMultiplier(recording.reviewerMultiplier);
    setClient(recording.client);
    setTranslator(recording.translator || "");
    setReviewer(recording.reviewer || "");
    setDueDate(moment(recording.dueDate).format("YYYY-MM-DDTHH:mm"));
    setMaxDueDate(moment(recording.maxDueDate).format("YYYY-MM-DDTHH:mm"));
    setpublicationDate(recording.publicationDate);
    setDuration(recording.duration);
    setMinutos(recording.minutos);
    setSegundos(recording.segundos);
    setConvict(recording.convict);
    setName(recording.name);
    setRecording(recording);
  }

  async function updateClientes() {
    getClients(getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve client's information due to an internal server error."
        );
      setClients(response.body);
    });
  }

  async function updateTranslators() {
    getTranslators(getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve translator's information due to an internal server error."
        );
      setTranslators(response.body);
    });
  }

  async function updateReviewers() {
    getReviewers(getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve reviewer's information due to an internal server error."
        );
      setReviewers(response.body);
    });
  }

  function save() {
    const minutosComoNumero = minutos ? parseInt(minutos) : 0;
    const segundosComoNumero = segundos ? parseInt(segundos) : 0;
    const recordingUpdated = {
      id,
      name,
      duration: minutosComoNumero + segundosComoNumero / 60,
      minutos: minutosComoNumero,
      segundos: segundosComoNumero,
      convict,
      publicationDate,
      dueDate: moment(dueDate).format(),
      maxDueDate: moment(maxDueDate).format(),
      translatorFile: deleteTranslatorFile ? null : recording.translatorFile,
      reviewerFile: deleteReviewerFile ? null : recording.reviewerFile,
      reviewerMultiplier,
      translatorMultiplier,
      client,
      reviewer: enIngles ? null : reviewer,
      translator: enIngles ? null : translator,
    };
    updateRecording(recordingUpdated, getToken()).then((response) => {
      if (response.status === 403) {
        logout();
        navigate("/");
        return toast.error("You are not allowed to see this content.");
      }
      if (response.status === 500)
        return toast.error(
          "Couldn't retrieve reviewer's information due to an internal server error."
        );
      toast.success("Recording updated successfully");
      navigate("/home/recordings");
    });
  }

  return (
    <div style={{ marginTop: "10px" }}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            id="name"
            label="Name"
            variant="outlined"
            type="string"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlinedMinutes"
            type="number"
            sx={{ width: "50%" }}
            value={minutos}
            InputProps={{
              endAdornment: <InputAdornment position="end">min</InputAdornment>,
            }}
            onChange={(e) => setMinutos(e.target.value)}
          />
          <TextField
            id="outlinedSeconds"
            type="number"
            value={segundos}
            sx={{ width: "50%" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">seg</InputAdornment>,
            }}
            onChange={(e) => setSegundos(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="convict"
            label="Convict"
            variant="outlined"
            type="string"
            fullWidth
            value={convict}
            onChange={(e) => setConvict(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <DateBox
            height="45px"
            label="Publication Date: "
            value={publicationDate}
            onValueChanged={(e) => setpublicationDate(e.value)}
            type="date"
          />
        </Grid>
        <Grid item xs={6}>
          <DateBox
            height="45px"
            label="Due Date: "
            value={dueDate}
            onValueChanged={(e) => setDueDate(e.value)}
            type="datetime"
          />
        </Grid>
        <Grid item xs={6}>
          <DateBox
            height="45px"
            label="Max Due Date: "
            value={maxDueDate}
            onValueChanged={(e) => setMaxDueDate(e.value)}
            type="datetime"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="translatorMultiplier"
            label="Translator Multiplier"
            variant="outlined"
            type="number"
            fullWidth
            value={translatorMultiplier}
            onChange={(e) => setTranslatorMultiplier(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="reviewerMultiplier"
            label="Reviewer Multiplier"
            variant="outlined"
            type="number"
            fullWidth
            value={reviewerMultiplier}
            onChange={(e) => setReviewerMultiplier(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="client"
            select
            label="Client"
            value={client}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setClient(e.target.value)}
          >
            {clients.map((client) => (
              <MenuItem key={client.id} value={client.id}>
                {client.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} visibility={enIngles ? "hidden" : "visible"}>
          <TextField
            id="translator"
            select
            label="Translator"
            value={translator}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setTranslator(e.target.value)}
          >
            {translators.map((translator) => (
              <MenuItem key={translator.id} value={translator.id}>
                {translator.nombreCompleto}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} visibility={enIngles ? "hidden" : "visible"}>
          <TextField
            id="reviewer"
            select
            label="Reviewer"
            value={reviewer}
            fullWidth
            hidden={enIngles}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setReviewer(e.target.value)}
          >
            {reviewers.map((reviewer) => (
              <MenuItem key={reviewer.id} value={reviewer.id}>
                {reviewer.nombreCompleto}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={deleteReviewerFile}
                onChange={() => setDeleteReviewerFile(!deleteReviewerFile)}
                color="default"
              />
            }
            label="Delete reviewer file?"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={deleteTranslatorFile}
                onChange={() => setDeleteTranslatorFile(!deleteTranslatorFile)}
                color="default"
              />
            }
            label="Delete translator file?"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={enIngles}
                onChange={() => setEnIngles(!enIngles)}
                color="default"
              />
            }
            label="Is recording in English?"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "red",
              width: 150,
              marginRight: "50px",
            }}
            onClick={() => navigate("/home/recordings")}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#aebd36",
              width: 150,
            }}
            onClick={save}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export { ReacordingEdition };
