import React from "react";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../Context/authContext";
import { TRANSLATOR } from "../Utils/config";

export default function MySettings({setChangePassword}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    logout,
    getNombre,
    getId,
    getRol
  } = React.useContext(AuthContext)
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  

  const closeSession = () => {
    logout()
    navigate(`/`);
    toast.info('Session ended successfully')
  };


  const handleClose = () => {
    setAnchorEl(null);    
  };

  const handleMySettings= ()=>{
    setChangePassword(true);
  }

  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <p>{getNombre()} {getRol() === TRANSLATOR? ` - Translator No. ${getId()}`:""}</p>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>      
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleMySettings}>Change Password</MenuItem>
        <MenuItem onClick={closeSession}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
