import React from "react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import { HOST } from "../../Utils/config";
import { AuthContext } from "../../Context/authContext";

function LoginInput() {
  const ariaLabel = { 'aria-label': 'description' };
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");  
  const {
    login
  } = React.useContext(AuthContext);

  const navigate = useNavigate();

  function updateUsername(e) {
    setUsername(e.target.value);
  }

  function updatePassword(e) {
    setPassword(e.target.value);
  }

  function credentialsComplete() {
    return username !== "" && password !== "";
  }

  const handleOnSubmitLogin = async () => {
    if (credentialsComplete()) {
      const response = await fetch(`${HOST}/api/v1/usuarios/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"          
        },
        body: JSON.stringify({
          email: username,
          password,
        }),
      });
      if (response.ok) {
        response.json().then(res => {              
          login(JSON.stringify(res.body))                
          navigate(`/home`);
        })        
      } else {
        toast.error("Usuario o contraseña incorrecto");
      }
    } else {
      toast.warning("Ingrese un usuario y contraseña");
    }
  };

  return (
    <div style={centeredDiv}>
      <div style={loginContainer}>
        <h2 style={{color: "white"}}>Welcome!</h2>
        <TextField        
          id="outlined-username"
          placeholder="Username"
          variant="outlined"
          value={username}
          margin="dense"
          onChange={updateUsername}
          sx={{ margin: "18px 0", backgroundColor: "white", borderRadius: "15px" }}
        />
        <TextField
          id="outlined-password"
          placeholder="Password"
          variant="outlined"
          value={password}
          onChange={updatePassword}
          type="password"
          sx={{ margin: "18px 0", backgroundColor: "white", borderRadius: "15px" }}
        />
        <div className="buttons__container">
          <Button
            variant="contained"
            color="success"
            sx={{ margin: "5px 0", backgroundColor: "#0c96e8" }}
            onClick={handleOnSubmitLogin}
          >
            Login
          </Button>
        </div>

        {/* <p>¿Olvidaste tu contraseña?</p> */}
      </div>
    </div>
  );
}

export {LoginInput}

const centeredDiv = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  alignSelf: "center",
  height: "calc(100vh - 60px)"  ,
  backgroundImage: "url('/waves.png')",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover"

};

const loginContainer = {
  // backgroundColor: "rgba(255, 255, 255)",  
  borderRadius: "30px",
  padding: "20px",
  // border: "3px solid rgb(128, 123, 123)",
  display: "flex",
  flexDirection: "column",  
  minWidth: "20rem",
  height: "22rem",
  alignSelf: "center",
};
